import React from "react"
import { useTranslation } from "react-i18next"
import * as S from "./styles"

export default function Selection({ fieldId, fieldValue, changeFieldValue, options }) {

  const { t } = useTranslation()

  const handleChange = (event) => {
    const selectedItem = options.find(option => option.VALUE === event.target.value)

    changeFieldValue({
      field: fieldId,
      value: selectedItem
    })
  }

  return (
    <S.Container>
      <S.Input onChange={handleChange}>
        <S.Option value="" label={t("Select")} />
        {options.map(({ VALUE, LABEL }) => (
          <S.Option
            key={VALUE}
            value={VALUE}
            label={t(LABEL)}
            selected={fieldValue.VALUE === VALUE}
          />
        ))}
      </S.Input>

    </S.Container>
  )
}
