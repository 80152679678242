import { useState, useEffect } from "react"
import getFormData from "../../../../services/registration/getFormData"

export default function useForm({ entity, staticFields, updateForm, models, screenBuilder }) {
  const [formData, setFormData] = useState({
    fields: [],
    tabs: []
  })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    (async () => {
      setLoading(true)
      await getFormData({
        entity,
        staticFields: await staticFields,
        models,
        screenBuilder
      })
        .then(({ orderedFields, uniqueTabs }) => {
          setFormData({
            fields: orderedFields,
            tabs: uniqueTabs
          })
          setLoading(false)
        })
    })()
  }, [])

  return {
    fields: formData.fields,
    tabs: formData.tabs,
    loading
  }
}
