import { useEffect, useState } from "react"
import { Platform } from "react-native"
import evaluateFunction from "../../../../../../services/evaluateFunction"

interface UsePeriodProps {
  models: any
  field: any
  updateForm: boolean
  setUpdateForm: (s) => void
}

export default function usePeriod({ models, field, updateForm }: UsePeriodProps) {

  const { ID, TABLENAME, isFilter } = field

  const ini = ID + "_INI"
  const fin = ID + "_FIN"
  const ATR = field.T_DTD_ATR ?? {}

  const [initial, setInitial] = useState("")
  const [final, setFinal] = useState("")

  const isEditable = ATR.EDITABLE ?? true
  const isRequired = ATR.REQUIRED ?? false

  const commonProps = {
    isEditable,
    isRequired,
    onBlur: () => onSubmit()
  }

  const onSubmit = () => {
    if (!isEditable) return

    const formattedInitial = initial

    const formattedFinal = final

    const updateModelDates = () => {
      /*   if (isFilter) {
          models._REGISTRATION_DATA[TABLENAME].filtersValues[ini] = formattedInitial
          models._REGISTRATION_DATA[TABLENAME].filtersValues[fin] = formattedFinal
        } */
      //else {
      if (Platform.OS !== "web") {
        const iniValue = formatDate(formattedInitial)
        const finValue = formatDate(formattedFinal)

        models[TABLENAME][ini] = iniValue ? iniValue : null
        models[TABLENAME][fin] = finValue ? finValue : null
      } else {
        models[TABLENAME][ini] = formattedInitial
        models[TABLENAME][fin] = formattedFinal
      }

      //}

      if (!formattedInitial) setInitial(formattedInitial)
      if (!formattedFinal) setFinal(formattedFinal)
    }

    updateModelDates()
  }

  const formatDate = str => {
    if (!str) return null

    const [day, month, year] = str.split("/")
    const formattedDate = `${year?.trim()}-${month?.trim()}-${day?.trim()}`

    return `${formattedDate}`
  }

  const formatLocalDate = str => {
    if (!str) return null

    const [year, month, day] = str.split("-")
    const formattedDate = `${day?.trim()}-${month?.trim()}-${year?.trim()}`

    return `${formattedDate}`
  }

  useEffect(() => {

    (async () => {
      if (ATR.EXPRESSION) {
        await evaluateFunction(ATR.EXPRESSION, models, {
          setInitial: setInitial,
          setFinal: setFinal
        })
        return
      }

      const modelValue = models[TABLENAME]

      if (modelValue[ini] || modelValue[fin]) {
        if (Platform.OS !== "web") {
          setInitial(formatLocalDate(modelValue[ini]))
          setFinal(formatLocalDate(modelValue[fin]))
        } else {
          setInitial(modelValue[ini])
          setFinal(modelValue[fin])
        }
        return
      }

      setInitial(null)
      setFinal(null)

    })()
  }, [updateForm])

  return {
    ATR,
    isRequired,
    initial,
    final,
    commonProps,
    setFinal,
    setInitial
  }
}
