import {
  KanbanComponent,
  ColumnsDirective,
  ColumnDirective
} from "@syncfusion/ej2-react-kanban"
import React, { useEffect, useState } from "react"
import KanbanToolbar from "../Toolbars/Kanban"
import handleCopy from "./utils/handleCopy"
import handleDelete from "./utils/handleDelete"
import handleInsert from "./utils/handleInsert"
import handleSave from "./utils/handleSave"
import "./styles.css"
import getFormattedRecords from "../Grid/utils/getFormattedRecords"

export default function Kanban({
  models,
  fields,
  records,
  entity,
  gridRef,
  setIsKanban,
  screenType,
  detailTabs,
  loading,
  toggleMode,
  mode,
  updateGridRecords
}) {

  const [selectedCard, setSelectedCard] = useState(null)
  const [filteredRecords, setFilteredRecords] = useState([])
  const [update, setUpdate] = useState(false)
  const [clickTimeout, setClickTimeout] = useState(null)

  const functionProps = {
    toggleMode,
    entity,
    models,
    selectedCard,
    setSelectedCard,
    setUpdate,
    mode
  }

  const kanbanFunctions = {
    handleInsert: ({ changeLoadingModalVisibility }) => handleInsert({
      ...functionProps,
      changeLoadingModalVisibility
    }),
    handleCopy: () => handleCopy(functionProps),
    handleDelete: ({ changeLoadingModalVisibility, updateGrid = () => null }) =>
      handleDelete({
        ...functionProps,
        changeLoadingModalVisibility,
        updateGrid
      })
  }

  if (screenType !== "searchModal") {
    kanbanFunctions.changeView = () => {
      setIsKanban((s) => !s)

      const recordIndex = records.findIndex((record) => record.ID === selectedCard?.ID)
      if (recordIndex !== -1) gridRef.current.selectRows([recordIndex])
    }
    kanbanFunctions.handleRefresh = async ({ changeLoadingModalVisibility }) => {
      changeLoadingModalVisibility(true)
      await updateGridRecords()
      changeLoadingModalVisibility(false)
      setTimeout(() => {
        setUpdate((s) => !s)
      }, 1000)
    }
  }

  let kanbanField = fields.find(
    (field) => field.FIELDTYPE.VALUE === "L" && field.T_DTD_ATR?.ISKANBAN
  )

  if (!kanbanField) kanbanField = {
    ID: "",
    T_DTD_OPT: {}
  }

  const { ID, T_DTD_OPT } = kanbanField

  const columns = Object.values(T_DTD_OPT)
    .sort((a, b) => a.ORDERBY - b.ORDERBY)
    .map((value) => value.LABEL)

  const onActionComplete = async (e) => {
    if (e.requestType === "cardChanged") {
      const isSaved = await handleSave({
        eventData: e.changedRecords[0],
        isUpdate: true,
        entityName: entity,
        models: models,
        kanbanField: ID,
        options: T_DTD_OPT,
        setUpdate: () => kanbanFunctions.handleRefresh({ changeLoadingModalVisibility: () => null })
      })

      if (isSaved) {
        document.querySelectorAll(".e-card").forEach((card) => {
          card.classList.remove("persist-selection")
        })
        setSelectedCard(null)
      }
    }
  }

  const onCardClick = (args) => {
    if (clickTimeout) {
      clearTimeout(clickTimeout)
      setClickTimeout(null)
      handleDoubleClick(args)
    } else {
      setClickTimeout(
        setTimeout(() => {
          handleSingleClick(args)
          setClickTimeout(null)
        }, 400)
      )
    }
  }

  const handleSingleClick = (args, isDoubleClick) => {
    const rowData = args.data
    const tableData = models._REGISTRATION_DATA[entity]
    const tableRecords = tableData.records
    const selectedRecordIndex = tableRecords.findIndex((record) => record.ID == rowData.ALTID)

    if (rowData.ID === selectedCard?.ID && !isDoubleClick) {
      setSelectedCard(null)
      models[entity].reset()
      document.querySelectorAll(".e-card").forEach((card) => {
        card.classList.remove("persist-selection", "e-selection")
      })
      return
    }

    tableData.currentRecordIndex = selectedRecordIndex
    const selectedRecord = tableRecords[selectedRecordIndex]
    models[entity].reset()
    models[entity].populate({ ...selectedRecord })
    tableData.originalRecord = { ...selectedRecord }
    tableData.operation = "UPD"

    if (screenType !== "orderPanel") {
      for (const table of detailTabs) {
        const tableID = table.ID
        const recordID = selectedRecord.ID
        if (screenType === "registrationMaster")
          tableData.detailTabsPath[tableID] = `${entity}/${recordID}/${tableID}`
        else
          tableData.detailTabsPath[tableID] = `${models._REGISTRATION_DATA[tableData.masterTable].detailTabsPath[entity]}/${recordID}/${tableID}`
      }
    }

    setSelectedCard({ ...rowData })

    setTimeout(() => {
      document.querySelectorAll(".e-card").forEach((card) => {
        card.classList.remove("persist-selection", "e-selection")
      })

      const selectedElement = document.querySelector(
        `.e-card[data-id="${args.data.ID}"]`
      )
      if (selectedElement) {
        selectedElement.classList.add("persist-selection")
      }
    }, 0)
  }

  const handleDoubleClick = (args) => {
    handleSingleClick(args, true)
    toggleMode() // Abre o formulário no duplo clique
  }

  useEffect(() => {
    if (models[entity]?.ID) {
      setSelectedCard(models[entity])
      document.querySelectorAll(".e-card").forEach((card) => {
        card.classList.remove("persist-selection", "e-selection")
      })

      const selectedElement = document.querySelector(
        `.e-card[data-id="${models[entity].ID}"]`
      )
      if (selectedElement) {
        selectedElement.classList.add("persist-selection")
      }
    }

    setFilteredRecords([])
    setFilteredRecords(getFormattedRecords({
      records: models._REGISTRATION_DATA[entity].records,
      models,
      entity
    }))
  }, [models._REGISTRATION_DATA[entity].records, loading, update, models[entity]?.ID])

  return (
    <div style={{
      display: "flex",
      flexDirection: "column"
    }}>
      <KanbanToolbar
        entity={entity}
        models={models}
        functions={kanbanFunctions}
        gridRef={gridRef}
        setFilteredRecords={setFilteredRecords}
        records={records}
      />

      {loading ?
        <></>
        :
        <div className="kanban-control-section">
          <div className="col-lg-12 control-section">
            <div className="control-wrapper">
              <KanbanComponent
                id={`${filteredRecords.length}-${loading}`}
                key={`${filteredRecords.length}-${loading}`}
                keyField={ID}
                dataSource={loading ? [] : filteredRecords}
                cardClick={onCardClick}
                actionComplete={onActionComplete}
                enableVirtualization={true}
                cardDoubleClick={(args) => {
                  args.cancel = true
                  // Duplo clique é controlado no onCardClick
                }}
                cardSettings={{
                  headerField: "ID",
                  showHeader: false,
                  contentField: "DESCRIPTION",
                  tagsField: "CREATED_BY"
                }}
              >
                <ColumnsDirective>
                  {columns.map((column) => (
                    <ColumnDirective
                      key={column}
                      keyField={column}
                      headerText={column}
                      allowToggle={true}
                      width="1500px"
                    />
                  ))}
                </ColumnsDirective>
              </KanbanComponent>
            </div>
          </div>
        </div>
      }
    </div>
  )
}
