import AsyncStorage from "@react-native-async-storage/async-storage"
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { Platform } from "react-native"
import { LocaleConfig } from "react-native-calendars"
import dbRequest from "./dbRequest/dbRequest"

const LANGUAGE_KEY = "user-language"
const TRANSLATION_CACHE_KEY = "translations-cache"
const TRANSLATION_COUNT_KEY = "translations-count"

const getStorageItem = async (key) => {
  try {
    if (Platform.OS === "web") {
      return localStorage.getItem(key)
    } else {
      return await AsyncStorage.getItem(key)
    }
  } catch (error) {
    console.error("Error getting storage item:", error)
    return null
  }
}

const setStorageItem = async (key, value) => {
  try {
    if (Platform.OS === "web") {
      localStorage.setItem(key, value)
    } else {
      await AsyncStorage.setItem(key, value)
    }
  } catch (error) {
    console.error("Error setting storage item:", error)
  }
}

const setCalendaroLocaleConfig = () => {
  LocaleConfig.locales["pt-br"] = {
    monthNames: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro"
    ],
    monthNamesShort: ["Jan.", "Fev.", "Mar.", "Abr.", "Mai.", "Jun.", "Jul.", "Ago.", "Set.", "Out.", "Nov.", "Dez."],
    dayNames: ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"],
    dayNamesShort: ["Dom.", "Seg.", "Ter.", "Qua.", "Qui.", "Sex.", "Sáb."],
    today: "Hoje"
  }

  LocaleConfig.defaultLocale = "pt-br"

}

export default async function setTranslations(fetchTranslations = true) {
  setCalendaroLocaleConfig()
  const cachedTranslations = await getStorageItem(TRANSLATION_CACHE_KEY)
  const cachedTranslationCount = await getStorageItem(TRANSLATION_COUNT_KEY)
  let resources = {}

  if (cachedTranslations) {
    resources = JSON.parse(cachedTranslations)
  }

  let translations = []

  if (fetchTranslations) {
    translations = await dbRequest.loadRecords("T_DTD_I18N").execute()
  }

  const languages = cachedTranslations
    ? Object.keys(resources).map((lang) => ({
      description: lang,
      translation: resources[lang].translation
    }))
    : [
      {
        description: "de",
        translation: {}
      },
      {
        description: "pt",
        translation: {}
      },
      {
        description: "en",
        translation: {}
      },
      {
        description: "es",
        translation: {}
      },
      {
        description: "fr",
        translation: {}
      },
      {
        description: "ja",
        translation: {}
      }
    ]

  const newResources = {}

  for (const language of languages) {
    newResources[language.description] = { translation: { ...language.translation } }
  }

  for (const translation of translations) {
    for (const language of languages) {
      const { description } = language
      newResources[description].translation[translation.ID] =
        translation[description] ?? translation.ID
    }
  }

  const isTranslationsChanged =
    JSON.stringify(resources) !== JSON.stringify(newResources) ||
    cachedTranslationCount !== translations.length.toString()

  if (isTranslationsChanged) {
    await setStorageItem(TRANSLATION_CACHE_KEY, JSON.stringify(newResources))
    await setStorageItem(TRANSLATION_COUNT_KEY, translations.length.toString())
  }

  await initializeI18n(newResources)
}

const initializeI18n = async (resources) => {
  const storedLanguage = await getStorageItem(LANGUAGE_KEY)

  i18n
    .use(initReactI18next)
    .init({
      compatibilityJSON: "v3",
      lng: storedLanguage || "en",
      fallbackLng: "en",
      resources,
      react: { useSuspense: false },
      interpolation: { escapeValue: false }
    })
}
