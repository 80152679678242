/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import evaluateFunction from "../../../../../../services/evaluateFunction"
import Checkbox from "../Inputs/Checkbox"
import Currency from "../Inputs/Currency"
import DateInput from "../Inputs/DateInput"
import DateTime from "../Inputs/DateTime"
import Decimal from "../Inputs/Decimal"
import Hours from "../Inputs/Hours"
import MultiSelect from "../Inputs/MultiSelect"
import Search from "../Inputs/Search"
import Selection from "../Inputs/Selection"
import Text from "../Inputs/Text"
import { FieldContent, FieldTitle, FieldValue } from "./styles"

export default function RenderField({ field, item, selectedUnit, handleCalculation, defaultAmount, editAmountNeg, isOrderScreen, models, params, changeFieldValue }) {

  const { t } = useTranslation()

  const { ID, FIELDTYPE, T_DTD_ATR } = field

  if (field.FIELDTYPE.VALUE === "E" || field.FIELDTYPE.VALUE === "G" || field.FIELDTYPE.VALUE === "A" || !field.T_DTD_ATR.CARDVISIBLE) return

  const [value, setValue] = useState(0)

  async function execExpression() {
    const response = await evaluateFunction(field.T_DTD_ATR.EXPRESSION, models, params)
    field.FIELDTYPE.VALUE === "C" ?
      setValue(`R$${response?.toLocaleString("pt-BR", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      })}`)
      :
      setValue(response)
  }

  useEffect(() => {
    (async () => {

      switch (field.FIELDTYPE.VALUE) {
        case "B":
          if (field.T_DTD_ATR?.EXPRESSION) execExpression()
          else setValue(item[field.ID] ? "Yes" : "No")
          break
        case "L":
          if (field.T_DTD_ATR?.EXPRESSION) execExpression()
          else setValue(item[field.ID]?.LABEL)
          break
        case "K":
        case "N": {
          if (field.T_DTD_ATR?.EXPRESSION) execExpression()
          else setValue(item[field.ID].LABEL)
          break
        }
        case "C":
          if (field.T_DTD_ATR?.EXPRESSION) execExpression()
          else setValue(`R$ ${Number(item?.[field.ID])?.toLocaleString("pt-BR", {
            maximumFractionDigits: !isNaN(selectedUnit?.VALUEDEC) ? selectedUnit?.VALUEDEC ?? 2 : 2,
            minimumFractionDigits: !isNaN(selectedUnit?.VALUEDEC) ? selectedUnit?.VALUEDEC ?? 2 : 2
          })}`)

          break
        default:
          if (field.T_DTD_ATR?.EXPRESSION) execExpression()
          else setValue(item[field.ID])
          break
      }
    })()
  }, [params])

  function sizeConvert(size) {
    switch (size?.toUpperCase()) {
      case "M": return "33%"
      case "L": return "50%"
      default: return "18%"
    }
  }

  const getInput = () => {
    switch (FIELDTYPE.VALUE) {
      case "B": return <Checkbox
        fieldId={ID}
        changeFieldValue={changeFieldValue}
      />

      case "C":
        const isAmountNegField = ID === "AMOUNTNEG"
        return <Currency
          fieldId={ID}
          fieldValue={isAmountNegField ? defaultAmount : item[ID]}
          editable={isAmountNegField ? editAmountNeg : true}
          handleCalculation={handleCalculation}
          changeFieldValue={changeFieldValue}
          precision={!isNaN(selectedUnit?.VALUEDEC) ? selectedUnit?.VALUEDEC ?? 2 : 2}
        />

      case "D": return <DateInput
        fieldId={ID}
        fieldValue={item[ID]}
        changeFieldValue={changeFieldValue}
      />

      case "F": return <Decimal
        fieldId={ID}
        fieldValue={item[ID]}
        changeFieldValue={changeFieldValue}
        selectedUnit={selectedUnit}
        handleCalculation={handleCalculation}
      />

      case "H": return <Hours
        fieldId={ID}
        fieldValue={item[ID]}
        changeFieldValue={changeFieldValue}
      />

      case "N":
      case "K": return <Search
        fieldId={ID}
        fieldValue={item[ID]}
        fieldAttributes={T_DTD_ATR}
        changeFieldValue={changeFieldValue}
        models={models}
        entityFields={field?.ENTITYFIELDS ?? []}
      />

      case "L": return <Selection
        fieldId={ID}
        fieldValue={item[ID]}
        changeFieldValue={changeFieldValue}
        options={T_DTD_ATR?.T_DTD_OPT ?? []}
      />

      case "M":
      case "S": return <Text
        fieldValue={item[ID]}
        fieldId={ID}
        fieldDescription={T_DTD_ATR.FIELDDESCRIPTION}
        changeFieldValue={changeFieldValue}
      />

      case "T": return <DateTime
        fieldId={ID}
        fieldValue={item[ID]}
        changeFieldValue={changeFieldValue}
      />

      case "O": return <MultiSelect
        fieldId={ID}
        fieldValue={item[ID]}
        changeFieldValue={changeFieldValue}
        options={T_DTD_ATR?.T_DTD_OPT ?? []}
      />
      default:
        return <></>
    }
  }

  return (
    <FieldContent style={{ width: sizeConvert(field.T_DTD_ATR?.SIZE?.VALUE) }}>
      <FieldTitle>{t(field.T_DTD_ATR.FIELDDESCRIPTION)}</FieldTitle>
      {T_DTD_ATR.EDITABLE && !isOrderScreen ?
        getInput()
        :
        <FieldValue>{value}</FieldValue>
      }
    </FieldContent>
  )
}
