export default date => {
  const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/
  if (!dateRegex.test(date)) return false

  const [day, month, year] = date.split("/").map(Number)

  if (month < 1 || month > 12) return false

  const daysInMonth = new Date(year, month, 0).getDate()

  if (day < 1 || day > daysInMonth) return false

  if (month === 2 && day === 29) {
    if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) return true
    else return false
  }

  return true
}
