import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import * as S from "./styles"

const MultiSelect = ({ fieldId, fieldValue, changeFieldValue, options }) => {

  const { t } = useTranslation()

  const fieldValueLength = Array.isArray(fieldValue) ? fieldValue.length : 0
  const [state, setState] = useState(fieldValueLength)

  return (
    <S.Container>
      <S.Input
        data={options}
        dropdownPosition='bottom'
        labelField='LABEL'
        valueField='VALUE'
        onChange={item => {
          const selectedOptions = options.filter((opt) =>
            item.includes(opt.VALUE)
          )

          setState(selectedOptions.length)

          changeFieldValue({
            field: fieldId,
            value: selectedOptions
          })
        }}
        placeholder={t("Selected itens") + " (" + state + ")"}
        renderSelectedItem={() => <></>}
      />
    </S.Container>
  )
}

export default MultiSelect
