import { ScheduleComponent } from "@syncfusion/ej2-react-schedule"
import React, { useEffect, useRef, useState } from "react"
import "./styles.css"
import LoginData from "../../../models/loginData"
import Schedule from "./components/Schedule"
import Sidebar from "./components/Sidebar"
import Toolbar from "./components/Toolbar"
import fetchEvents from "./utils/fetchEvents"
import fetchGroups from "./utils/fetchGroups"
import fetchUserSettings from "./utils/fetchUserSettings"

export default function CalendarScreen() {

  const scheduleObj = useRef<ScheduleComponent>(null)
  const workWeekObj = useRef(null)
  const resourceObj = useRef(null)
  const contextMenuObj = useRef(null)

  const [eventData, setEnventData] = useState([])
  const [calendarCollections, setCalendarCollections] = useState([])
  const [scheduleSettings, setScheduleSettings] = useState({
    selectedCalendars: [LoginData.getLoginData().ID],
    selectedView: "Week",
    isTimelineView: false,
    isGroupingView: false,
    resourcesGroups: [],
    enableGridline: false,
    rowAutoHeight: false,
    firstDayOfWeek: 0,
    workDays: [1, 2, 3, 4, 5],
    startHour: "00:00",
    endHour: "23:59",
    workStartHour: "09:00",
    workEndHour: "18:00",
    slotDuration: 60,
    slotInterval: 2,
    showWeekNumber: false,
    weekRule: "Off",
    enableTooltip: false
  })

  useEffect(() => {
    let updatedView: any = scheduleSettings.selectedView
    switch (scheduleSettings.selectedView) {
      case "Day":
      case "TimelineDay":
        updatedView = scheduleSettings.isTimelineView ? "TimelineDay" : "Day"
        break
      case "Week":
      case "TimelineWeek":
        updatedView = scheduleSettings.isTimelineView ? "TimelineWeek" : "Week"
        break
      case "WorkWeek":
      case "TimelineWorkWeek":
        updatedView = scheduleSettings.isTimelineView ? "TimelineWorkWeek" : "WorkWeek"
        break
      case "Month":
      case "TimelineMonth":
        updatedView = scheduleSettings.isTimelineView ? "TimelineMonth" : "Month"
        break
      case "Year":
      case "TimelineYear":
        updatedView = scheduleSettings.isTimelineView ? "TimelineYear" : "Year"
        break
      case "Agenda":
        updatedView = "Agenda"
        break
      default:
        break
    }
    scheduleObj.current.currentView = updatedView
  }, [scheduleSettings.selectedView, scheduleSettings.isTimelineView])

  useEffect(() => {
    (async () => {
      try {
        await Promise.all([
          fetchGroups(setScheduleSettings, setCalendarCollections),
          fetchUserSettings({
            scheduleObj,
            setScheduleSettings
          })
        ])
        await fetchEvents().then((data) => setEnventData(data))
      } catch (error) {
        console.error("Erro ao buscar dados:", error)
      }
    })()
  }, [])

  return (
    <div className='schedule-control-section'>
      <div className='col-lg-12 control-section'>
        <div className='content-wrapper'>
          <div className='schedule-overview'>
            <Toolbar
              scheduleObj={scheduleObj}
              workWeekObj={workWeekObj}
              resourceObj={resourceObj}
              setEventData={setEnventData}
              scheduleSettings={scheduleSettings}
              setScheduleSettings={setScheduleSettings}
            />
            <div className='overview-content'>
              <Schedule
                scheduleObj={scheduleObj}
                eventData={eventData}
                scheduleSettings={scheduleSettings}
                contextMenuObj={contextMenuObj}
                setEventData={setEnventData}
                calendarCollections={calendarCollections}
              />
              <Sidebar
                resourceObj={resourceObj}
                scheduleObj={scheduleObj}
                workWeekObj={workWeekObj}
                calendarCollections={calendarCollections}
                scheduleSettings={scheduleSettings}
                setScheduleSettings={setScheduleSettings}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

