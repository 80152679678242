import { useEffect, useState } from "react"
import LoginData from "../../../../models/loginData"
import dbRequest from "../../../../services/dbRequest/dbRequest"
import getTableFields from "../../../../services/registration/getTableFields"
import addNativeFunctions from "../../Panel/components/MoreOptionsButton/utils/addNativeFunctions"

export default function useRegistration(props) {
  const { entityName, record = null, mode, models } = props

  const [selectedItem, setSelectedItem] = useState(null)
  const [searchText, setSearchText] = useState("")
  const [updateTable, setUpdateTable] = useState(false)
  const [updateForm, setUpdateForm] = useState(false)
  const [options, setOptions] = useState({
    table: [],
    form: []
  })
  const [isFetchingTableData, setIsFetchingTableData] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [fields, options] = await Promise.all([
          getTableFields({ entityName }),
          getOptions(entityName, models, mode)
        ])

        populateModels({
          fields,
          ...props
        })

        setOptions(options)
        setIsFetchingTableData(false)
      } catch (error) {
        console.error("Erro ao buscar dados:", error)
      }
    }

    fetchData()
  }, [record])

  return {
    selectedItem,
    setSelectedItem,
    options,
    searchText,
    setSearchText,
    updateTable,
    setUpdateTable,
    updateForm,
    setUpdateForm,
    isFetchingTableData
  }
}

function populateModels({
  models,
  entityName,
  fields,
  record = null,
  changeMode,
  mode,
  setUpdateForm
}) {
  const tableData = models._REGISTRATION_DATA[entityName]

  tableData.fields = fields

  if (record) {
    const detailTabs = fields.filter(
      ({ FIELDTYPE, T_DTD_ATR }) => FIELDTYPE.VALUE === "E" && T_DTD_ATR.VISIBLE
    )

    for (const table of detailTabs) {
      const tableID = table.ID
      const recordID = record.ID
      const masterTable = tableData.masterTable

      tableData.detailTabsPath[
        tableID
      ] = `${models._REGISTRATION_DATA[masterTable].detailTabsPath[entityName]}/${recordID}/${tableID}`
    }

    models[entityName].reset()
    models[entityName].populate(record)

    tableData.operation = "UPD"
    mode === "table" && changeMode()
    setUpdateForm((s) => !s)
  }
}

const getOptions = async (entityName, models, mode) => {
  const tableFunctions = await dbRequest
    .loadRecords(`T_DTD_TAB/${entityName}/T_DTD_FNC`)
    .execute()

  const { permissions } = models._REGISTRATION_DATA[entityName]

  const enabledFunctions = tableFunctions.filter(({ ACTIVE, ID }) => {
    const isFunctionActive = ACTIVE
    const isUserAdmin = LoginData.getLoginData().ADMIN
    const doesUserHasPermission = isUserAdmin || permissions[ID]
    return isFunctionActive && doesUserHasPermission
  })

  addNativeFunctions({
    functions: enabledFunctions,
    entityName,
    mode
  })

  const result = {
    table: enabledFunctions.filter((fn) => !fn.ONLYFORM),
    form: enabledFunctions
  }

  return result
}
