import Feather from "@expo/vector-icons/Feather"
import React, { useRef } from "react"
import { useDrag, useDrop } from "react-dnd"
import { useTranslation } from "react-i18next"
import { defaultTheme } from "../../../../../../../../../../../../../styles/themes/default"
import ItemTypes from "../../../ItemTypes"
import * as S from "./styles"

export default function Card({
  filterData,
  index,
  moveCard,
  setVisibleFields,
  setNonVisibleFields,
  isHeaderConfiguration,
  setModalVisible,
  setSelectedField
}) {
  const { t } = useTranslation()

  const ref = useRef(null)

  const { id, description, required, editable } = filterData

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover(item, monitor) {
      if (!ref.current) return

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === undefined || hoverIndex === undefined) return
      if (dragIndex === hoverIndex) return

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return

      moveCard(dragIndex, hoverIndex)

      item.index = hoverIndex
    }
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => ({
      id,
      index
    }),
    collect: (monitor) => ({ isDragging: monitor.isDragging() })
  })

  const cardOpacity = isDragging ? 0 : 1

  drag(drop(ref))

  const handlePress = (attribute) => {
    if (["QUANTITY", "UNITID"].includes(id)) return
    setVisibleFields((state) => {
      const newState = [...state]
      if (attribute === "type")
        newState[index][attribute] =
          newState[index][attribute] === "N" ? "K" : "N"
      else newState[index][attribute] = !newState[index][attribute]

      return newState
    })
  }

  const removeVisibleField = () => {
    setVisibleFields((state) => {
      return state.filter((filter) => filter.id !== id)
    })
  }

  const addNonVisibleField = () => {
    setNonVisibleFields((state) => {
      const newState = [
        ...state,
        {
          ...filterData,
          visible: false
        }
      ]

      newState.sort((a, b) => t(a.description).localeCompare(t(b.description)))
      return newState
    })
  }

  const isPrimaryKey = filterData.id === "TNSID"
  const isSearchField = ["K", "N"].includes(filterData.type)

  const hasExpression =
    filterData.expression !== undefined &&
    filterData.expression !== "" &&
    filterData.expression !== null

  return (
    <S.Container
      ref={ref}
      style={{
        opacity: cardOpacity,
        backgroundColor: ["QUANTITY", "UNITID"].includes(id) ? defaultTheme["gray-200"] : defaultTheme["white"]
      }}
      data-handler-id={handlerId}
    >

      <S.Text title={t(description)}>{index}. {t(description)}</S.Text>

      <S.ButtonsContainer>
        <S.ToogleContainer isActive={required} onClick={() => handlePress("required")}>
          {t("Required")}
        </S.ToogleContainer>
        <S.ToogleContainer isActive={editable} onClick={() => handlePress("editable")}>
          {t("Editable")}
        </S.ToogleContainer>

        {(!isPrimaryKey && isSearchField && isHeaderConfiguration) ? (
          <S.ToogleContainer isActive={filterData.type === "N"} onClick={() => handlePress("type")}>
            {t("Navigation")}
          </S.ToogleContainer>
        ) : (
          <S.InvisibleToogleContainer isActive={filterData.type === "N"} onClick={() => handlePress("type")}>
            {t("Navigation")}
          </S.InvisibleToogleContainer>
        )}

        <S.Button
          style={{
            backgroundColor: hasExpression ? defaultTheme["orange-500"] : defaultTheme["white"],
            border: hasExpression ? "none" : `1px solid ${defaultTheme["orange-500"]}`,
            visibility: (!isPrimaryKey && isSearchField && isHeaderConfiguration) ? "visible" : "hidden"
          }}
          title={hasExpression ? t("Edit Expression") : t("Add Expression")}
          onClick={() => {
            setSelectedField(filterData)
            setModalVisible(true)
          }}
        >
          <Feather name="code" size={14} color={hasExpression ? defaultTheme["white"] : defaultTheme["orange-500"]} />
        </S.Button>
        <S.Button
          style={{
            backgroundColor: defaultTheme["red-400"],
            visibility: ["QUANTITY", "UNITID"].includes(id) ? "hidden" : "visible"
          }}
          onClick={() => {
            removeVisibleField()
            addNonVisibleField()
          }}
        >
          <Feather name="trash-2" size={16} color={defaultTheme["white"]} />
        </S.Button>

      </S.ButtonsContainer>
    </S.Container>
  )
}
