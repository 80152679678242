import models from "../../../../models"
import Navigation from "../../../../models/navigation"
import Permissions from "../../../../models/permissions"
import showAlert from "../../../../services/showAlert"
import { homeScreenObserver } from "../../../screens/HomeScreen/index.web"
import { branchLogoObserver } from "../../BranchLogo"
import { headerObserver } from "../../Header/index.web"
import { menuDrawerObserver } from "../hooks/useMenu"
import updateBranch from "./updateBranch"

const BRANCH_KEY = "current-branch"

const branchSwap = ({ t, branchs, closeMenu, onUpdateNotifications }) => {
  // Pega a branch atual
  const currentBranch =
    localStorage.getItem(BRANCH_KEY) || Object.keys(branchs)[0]

  // Reordena as branches
  const reorderedBranchs = {
    [currentBranch]: branchs[currentBranch],
    ...Object.fromEntries(
      Object.entries(branchs).filter(([key]) => key !== currentBranch)
    )
  }

  showAlert({
    type: "select",
    selectInputOptions: reorderedBranchs,
    title: t("Branch Swap"),
    text: t("This action will close all tabs."),
    titleType: "warning",
    handleConfirm: async (value) => {
      if (value === currentBranch) {
        showAlert({
          type: "ok",
          titleType: "warning",
          title: t("Same branch"),
          text: t("This branch is already selected!")
        })
        return
      }

      if (value) {
        localStorage.setItem(BRANCH_KEY, value)

        updateBranch(value)
        Navigation.closeTabs()

        await Permissions.initialize()
        await models.prepareClone()

        onUpdateNotifications()
        closeMenu()
        showAlert({
          type: "ok",
          titleType: "success",
          title: "Branch changed successfully!",
          text: ""
        })

        homeScreenObserver.notify()
        menuDrawerObserver.notify()
        branchLogoObserver.notify()
        headerObserver.notify()
      }
    }
  })
}

export default branchSwap
