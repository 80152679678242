import styled from "styled-components"
import { defaultTheme } from "../../../../../styles/themes/default"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  height: 70%;
  background-color: ${defaultTheme["gray-200"]};
  overflow: hidden;
  border-radius: 20px;
`

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 15%;
  padding: 16px 20px;
`

export const ModalTitle = styled.h1`
  color: ${defaultTheme["gray-700"]};
  font-size: 22px;
  font-weight: 600;
  margin: 0px;
`

export const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;

  & > *:nth-child(3) {
    position: relative;
    left: 30px;
  }
`

export const MarkAllAsReadButton = styled.button`
  padding: 4px 6px;
  background-color: ${defaultTheme["white"]};
  border-radius: 14px;
  color: ${defaultTheme["white"]};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  &:hover {
    background-color: ${defaultTheme["gray-100"]};
    transition: background-color 0.5s ease;
  }
`

export const TitleButton = styled.h1`
  font-size: 12px;
  font-weight: 400;
  padding-right: 4px;
  color: ${defaultTheme["gray-600"]};
`

export const Icon = styled.div`
  background-color: ${defaultTheme["cyan-300"]};
  border-radius: 50%;
  margin-right: 2px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CloseButton = styled.a`
  display: flex;
  justify-content: center;
  cursor: pointer;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 85%;
  background-color: ${defaultTheme["white"]};
`

export const NotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;

  background-color: ${defaultTheme["gray-100"]};
`

export const Section = styled.div`
  margin-bottom: 14px;
  padding-top: 10px;
  margin: 0 20px;
`

export const NoNotifications = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${defaultTheme["gray-600"]};

`

export const SectionTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  color: ${defaultTheme["gray-700"]};
  margin-bottom: 16px;
`

export const NotificationItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 16px;
  background-color: ${defaultTheme["white"]};
  border-radius: 8px;
  margin-bottom: 12px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    background-color: ${defaultTheme["cyan-300"]}10;
  }
  &:active {
    background-color: ${defaultTheme["gray-200"]};
  }
`

export const NotificationsMarker = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 6px; 
  height: 6px; 
  border-radius: 50%;
  background-color: ${defaultTheme["red-500"]};
`

export const NotificationTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${defaultTheme["gray-700"]};
  margin-bottom: 4px;
`

export const NotificationMessage = styled.p`
  font-size: 12px;
  color: ${defaultTheme["gray-700"]};
  margin-left: 4px;
  margin-bottom: 10px;
`

export const NotificationDate = styled.span`
  font-size: 12px;
  color: ${defaultTheme["gray-500"]};
  text-align: right;
`
