import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import * as S from "./styles"

export default function Text({ fieldId, fieldValue, fieldDescription, changeFieldValue }) {
  const { t } = useTranslation()

  const [state, setState] = useState(fieldValue)

  return (
    <S.Container>
      <S.Input
        placeholder={t(fieldDescription)}
        value={state}
        onChange={e => {
          setState(e.target.value)
          changeFieldValue({
            field: fieldId,
            value: e.target.value
          })
        }}
      />
    </S.Container>
  )
}
