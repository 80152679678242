import dbRequest from "../services/dbRequest/dbRequest"
import Permissions from "./permissions"

export default class models {
  static original

  async generateModels() {
    const tables = await dbRequest.loadRecords("T_DTD_TAB").execute()

    this._REGISTRATION_DATA = {}

    for (const table of tables) {
      const { ID, ENTITY, FILTER, PKSOURCE, PKTYPE = {}, MASTERTAB = {}, TYPE = {}, ISKANBAN } = table

      this[ID] = new modelTemplate()

      this._REGISTRATION_DATA[ID] = {
        currentRecordIndex: null,
        description: ENTITY,
        detailTabsPath: {},
        fields: null,
        files: {},
        filterFields: [],
        filtersValues: {},
        savedProperties: null,
        headerSortingData: {
          columnName: null,
          sorting: "ASC"
        },
        manualID: PKTYPE.VALUE === "S" ? PKSOURCE.VALUE === "M" : true,
        masterTable: MASTERTAB.ID,
        operation: "INS",
        originalRecord: {},
        permissions: await Permissions.getPermissions(ID) ?? {},
        pkSource: PKSOURCE.VALUE,
        pkType: PKTYPE.VALUE,
        records: [],
        formattedRecords: {},
        state: "BEFORE",
        tableFilter: FILTER,
        transactionID: ID,
        type: TYPE.VALUE,
        isKanban: ISKANBAN
      }
    }

    this.generateDefaultModels()
  }

  generateDefaultModels() {
    const defaultModels = ["ACTION", "DASHBOARD", "MENU", "PARAMS", "REPORT"]

    for (const model of defaultModels) {
      this[model] = new modelTemplate()
      this._REGISTRATION_DATA[model] = {}
    }
  }

  static async prepareClone() {
    this.original = new models()
    await this.original.generateModels()
  }

  static getClone() {
    const clone = {}

    const getCloneValue = entity => {
      switch (entity) {
        case "_REGISTRATION_DATA": return { ...this.original[entity] }
        default: return new modelTemplate()
      }
    }

    for (const entity in this.original)
      clone[entity] = getCloneValue(entity)

    return clone
  }
}

class modelTemplate {
  populate(obj) {
    for (const key in obj)
      this[key] = obj[key]
  }

  reset() {
    for (const key in this)
      delete this[key]
  }
}
