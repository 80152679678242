import showAlert from "../../../../services/showAlert"
import { formObserver } from "../../Form/components/RenderTabs/"

const handleKanbanInsert = ({
  models,
  entity,
  toggleMode,
  mode,
  setUpdateForm,
  changeLoadingModalVisibility
}) => {

  const tableData = models._REGISTRATION_DATA[entity]

  const checkTableDataFields = (callback) => {
    changeLoadingModalVisibility(true)

    const interval = setInterval(() => {
      if (tableData.fields && tableData.fields.length > 0) {
        clearInterval(interval)
        changeLoadingModalVisibility(false)
        callback()
      }
    }, 100)
  }

  const proceedWithInsert = () => {
    if (mode === "grid") {
      models[entity].reset()
      tableData.operation = "INS"
      toggleMode()
      return
    }

    showAlert({
      text: "Do you want to insert a new record?",
      type: "confirm",
      titleType: "question",
      title: "Unsaved changes will be lost",
      handleConfirm: () => {
        if (typeof window !== "undefined") formObserver.notify(null)
        models[entity].reset()
        tableData.operation = "INS"
        tableData.currentRecordIndex = null
        setUpdateForm((s) => !s)
      }
    })
  }

  checkTableDataFields(proceedWithInsert)
}

export default handleKanbanInsert
