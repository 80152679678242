import dbRequest from "../../../../services/dbRequest/dbRequest"
import evaluateFunction from "../../../../services/evaluateFunction"
import showAlert from "../../../../services/showAlert"

export default async function reportRequest(idReport, models) {
  try {
    const reportData = await getReportData(idReport, models)

    const reportHtmlStructure = await getHtmlStructure(idReport, reportData)

    return reportHtmlStructure
  } catch (error) {
    console.error("Error executing reportRequest function:", error)
    throw error
  }
}

const getReportData = async (idReport, models) => {
  try {
    const reportData = await dbRequest
      .loadRecords(`T_RDB_REP/${idReport}/DATA`)
      .execute(async (data) => {
        const reportData = {}
        await Promise.all(data.map(async (mainCollection) => {
          reportData[mainCollection.ID] = []

          const dataPath = `T_RDB_REP/${idReport}/DATA`
          await processMainCollection(mainCollection, dataPath, reportData[mainCollection.ID], models)

          await processVariables(mainCollection, dataPath, reportData[mainCollection.ID], models)
          if (mainCollection.FILTER) reportData[mainCollection.ID] = applyFilters(mainCollection.FILTER, reportData[mainCollection.ID], models)
        }))
        return reportData
      })

    return reportData
  } catch (error) {
    console.error("Error getting report data:", error)
    throw error
  }
}

const processMainCollection = async (collection, dataPath, reportData, models) => {
  try {
    let query = dbRequest.loadRecords(collection.ENTITY)

    if (collection?.WHERE) {
      for (const where of collection.WHERE) {
        if ("STARTING_AT" in where) {
          const startingValue = getModelValue(where.STARTING_AT, models.REPORT)
          const endingValue = getModelValue(where.ENDING_AT, models.REPORT)

          if (startingValue && endingValue) {
            query = query.filterPeriod({
              FIELD: where.FIELD,
              STARTING_AT: startingValue,
              ENDING_AT: endingValue
            })
          }
        } else {
          if (where.VALUE.includes("models.")) where.VALUE = getModelValue(where.VALUE, models.REPORT)
          if (where.VALUE) query = query.where(where.FIELD, where.OPERATOR, where.VALUE)
        }
      }
    }

    const collectionData = await query.execute()

    const collectionConnections = await dbRequest
      .loadRecords(`${dataPath}/${collection.ID}/CONNECTIONS`)
      .execute(r => r.sort((a, b) => a.ORDERBY - b.ORDERBY))

    dataPath = `${dataPath}/${collection.ID}/CONNECTIONS`

    if (collectionData) {
      await Promise.all(collectionData.map(async (record) => {
        for (const field in record) {
          if (typeof record[field] === "object" && record[field] !== null) {
            if (Object.hasOwnProperty.call(record[field], "ID")) record[field] = { ID: record[field].ID }
          }
        }

        const tempReportData = []

        for (const collection of collectionConnections) {
          if (collection.CONNECTIONTYPE.VALUE === "field") {
            record = await adaptForeignFields(record, collection)
          }
        }

        await Promise.all(collectionConnections.map(async collection => {
          if (collection.CONNECTIONTYPE.VALUE === "detail") {
            const detailRecords = await getScreenDetailRecords(record, collection, `${dataPath}/${collection.ID}/CONNECTIONS`)
            await Promise.all(detailRecords.map(async detailRecord => {
              const copyOfRecord = { ...record }
              copyOfRecord[collection.ID] = detailRecord
              tempReportData.push(copyOfRecord)

              const subConnectionsPath = `${dataPath}/${collection.ID}/CONNECTIONS`
              const subConnections = await dbRequest.loadRecords(subConnectionsPath).execute()

              if (subConnections.length > 0) {
                await processSubConnections(copyOfRecord, subConnections, subConnectionsPath, reportData)
              }
            }))
          }
        }))

        if (tempReportData.length === 0) {
          reportData.push(record)
        } else {
          reportData.push(...tempReportData)
        }
      }))
    } else showAlert({
      titleType: "error",
      title: "Register not found."
    })
  } catch (error) {
    console.error("Error processing main collection:", error)
    throw error
  }
}

const processSubConnections = async (record, subConnections, subConnectionsPath, reportData) => {
  for (const subConnection of subConnections) {
    const subConnectionPath = `${subConnectionsPath}/${subConnection.ID}/CONNECTIONS`
    if (subConnection.CONNECTIONTYPE.VALUE === "field") {
      record = await adaptForeignFields(record, subConnection)
    } else if (subConnection.CONNECTIONTYPE.VALUE === "detail") {
      const detailRecords = await getScreenDetailRecords(record, subConnection)
      detailRecords.forEach(detailRecord => {
        const copyOfRecord = { ...record }
        copyOfRecord[subConnection.ID] = detailRecord
        reportData.push(copyOfRecord)
      })
    }

    const nestedSubConnections = await dbRequest.loadRecords(subConnectionPath).execute()
    if (nestedSubConnections.length > 0) {
      await processSubConnections({ ...record }, nestedSubConnections, subConnectionPath, reportData)
    }
  }
}

const adaptForeignFields = async (record, collection) => {
  let newObj = { ...record }

  const parts = collection.WHERE.VALUE.split(".")
  parts.shift()
  let value = newObj
  for (let i = 0; i < parts.length; i++) value = value[parts[i]]

  const foreignRecord = await dbRequest.loadRecords(collection.ENTITY)
    .where(collection.WHERE.FIELD, collection.WHERE.OPERATOR, value)
    .execute()

  if (collection.FIELD.includes(".")) {
    const parts = collection.FIELD.split(".")
    parts.shift()
    let targetObject = newObj

    for (let i = 0; i < parts.length - 1; i++) {
      targetObject = targetObject[parts[i]]
    }

    const fieldName = parts[parts.length - 1]
    targetObject[fieldName] = foreignRecord[0]
  } else newObj[collection.FIELD] = foreignRecord[0]

  return newObj
}

const getScreenDetailRecords = async (record, collection) => {
  const match = collection.COLLECTIONPATH.match(/\$\{([^}]+)\}/)
  const string = match ? match[1] : collection.COLLECTIONPATH
  const parts = string.split(".")
  parts.shift()

  let value = record
  for (let i = 0; i < parts.length; i++) value = value[parts[i]]

  const populateCollectionPath = collection.COLLECTIONPATH.replace(/\$\{[^}]+\}/g, value)

  const query = dbRequest.loadRecords(populateCollectionPath)

  if (collection.WHERE) {
    const parts = collection.WHERE.VALUE.split(".")
    parts.shift()
    let value = record
    for (let i = 0; i < parts.length; i++) {
      value = value[parts[i]]
    }
    query.where(collection.WHERE.FIELD, collection.WHERE.OPERATOR, value)
  }

  return await query.execute()
}

const processVariables = async (collection, dataPath, reportData, models) => {

  const variables = await dbRequest.loadRecords(`${dataPath}/${collection.ID}/VARIABLES`).execute()

  if (!variables.length) return

  for (const record of reportData) {
    for (const variable of variables) {

      const params = {
        record,
        reportData
      }

      record[variable.ID] = await evaluateFunction(variable.EXPRESSION, models, params)
    }
  }

}

function applyFilters(filtersArray, dataArray, models) {

  return dataArray.filter(item => {
    return filtersArray.every(filter => {
      let valueToCompare = filter.VALUE

      if (filter.VALUE.includes("models.")) {
        valueToCompare = getModelValue(filter.VALUE, models.REPORT)
      }

      if (!valueToCompare) return true

      switch (filter.OPERATOR) {
        case "==":
          return item[filter.FIELD] == valueToCompare
        case "!=":
          return item[filter.FIELD] != valueToCompare
        case ">":
          return item[filter.FIELD] > valueToCompare
        case ">=":
          return item[filter.FIELD] >= valueToCompare
        case "<":
          return item[filter.FIELD] < valueToCompare
        case "<=":
          return item[filter.FIELD] <= valueToCompare
        default:
          return true
      }
    })
  })
}

function getModelValue(str, models) {
  if (!str.includes("models.")) return str

  const prefix = "models."

  let field = null

  if (str.startsWith(prefix)) {
    field = str.substring(prefix.length)
  }

  let fieldValue = null

  if (field.includes(".")) {
    const parts = field.split(".")
    let value = models
    for (let i = 0; i < parts.length; i++) {
      value = value?.[parts[i]]
    }
    fieldValue = value
  } else fieldValue = models[field]

  return fieldValue ?? null
}

const getHtmlStructure = async (idReport, reportData) => {
  try {
    let htmlParts = ["<html>"]
    const htmlComponents = await dbRequest
      .loadRecords(`T_RDB_REP/${idReport}/HTML`)
      .execute(response => response.sort((a, b) => a.ORDERBY - b.ORDERBY))
    htmlParts.push("<script>const reportData = ", JSON.stringify(reportData), "</script>")

    htmlComponents.forEach(component => htmlParts.push(component.VALUE))

    htmlParts.push("</html>")
    const htmlStructure = htmlParts.join("")
    return htmlStructure
  } catch (error) {
    console.error("Error occurred:", error)
  }
}

/* const processHtmlComponent = (component, htmlParts, reportData) => {
  switch (component.TYPE.VALUE) {
    case "H": buildHeaderComponent(component, htmlParts, reportData); break
    case "S": buildTextComponent(component, htmlParts, reportData); break
    case "T": buildTableComponent(component, htmlParts, reportData); break
    case "C": buildChartComponent(component, htmlParts, reportData); break
    default: break
  }
} */

/* const buildHeaderComponent = (component, htmlParts) => {
  const { SCRIPT, DOCUMENTSTYLE, DOCUMENTTITLE } = component
  if (SCRIPT) htmlParts.push(SCRIPT)
  if (DOCUMENTSTYLE) htmlParts.push(DOCUMENTSTYLE)
  htmlParts.push(DOCUMENTTITLE)
}

const buildTextComponent = (component, htmlParts, reportData) => {
  let { HTML, SCRIPT } = component

  const regex = /{{(.*?)}}/g

  HTML = HTML.replace(regex, (match, group) => eval(`reportData.${group}`) || match)
  htmlParts.push(HTML)
  htmlParts.push(SCRIPT.replace(regex, (match, group) => eval(`reportData.${group}`) || match))
}

const buildTableComponent = (component, htmlParts, reportData) => {
  const { DATAPATH, HTML, HEADERSTYLE, HEADERCELL, ROWCELL, FOOTERSTYLE, GROUPING, ACCUMULATOR } = component

  let arrayData = eval(`reportData.${DATAPATH}`)

  arrayData = dataGrouping(arrayData, GROUPING)

  const headerCells = GROUPING

  htmlParts.push(HTML)
  htmlParts.push("<thead>")
  htmlParts.push(HEADERSTYLE)
  headerCells.forEach((cell) => {
    const regex = /{{(.*?)}}/g
    const htmlCell = HEADERCELL.replace(regex, cell.DESCRIPTION)
    htmlParts.push(htmlCell)
  })
  htmlParts.push("</thead>")

  htmlParts.push("<tbody>")

  arrayData.forEach(item => {
    htmlParts.push("<tr>")
    headerCells.forEach(cell => {
      const regex = /{{(.*?)}}/g
      const htmlRowCell = ROWCELL.replace(regex, formatString({
        value: item[cell.DESCRIPTION],
        formatType: cell.FORMATTYPE
      }))
      htmlParts.push(htmlRowCell)
    })
    htmlParts.push("</tr>")
  })
  htmlParts.push("</tbody>")

  htmlParts.push("<tfoot>")
  htmlParts.push(FOOTERSTYLE)

  const footerData = dataAccumulator(ACCUMULATOR, arrayData)

  headerCells.forEach(item => {
    for (const key in footerData) {
      if (key === item.DESCRIPTION) {
        const regex = /{{(.*?)}}/g
        const htmlRowCell = ROWCELL.replace(regex, formatString({
          value: footerData[key],
          formatType: item.FORMATTYPE
        }))
        htmlParts.push(htmlRowCell)
      } else htmlParts.push("<td></td>")
    }
  })

  htmlParts.push("</tr></tfoot></table>")
}

const buildChartComponent = (component, htmlParts, reportData) => {
  const { DATAPATH, HTML, GROUPING, ACCUMULATOR, SERIES, CLASSID, CHARTTYPE } = component

  let arrayData = eval(`reportData.${DATAPATH}`)

  arrayData = dataGrouping(arrayData, GROUPING)

  htmlParts.push(HTML)

  const chartData = {
    labels: [],
    datasets: []
  }

  for (const serie of SERIES) {
    const serieData = {
      label: "",
      data: []
    }

    serieData.label = serie.SERIETITLE
    arrayData.forEach(item => chartData.labels.push(item[serie.LABEL]))
    arrayData.forEach(item => serieData.data.push(item[serie.VALUE]))
    chartData.datasets.push(serieData)
  }

  const chartScript = `
    <script>

      const chartData = ${JSON.stringify(chartData)}

      const optionsGrafico = {
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: "#FFF",
            formatter: ((value, ctx)=> {
              const totalSum = ctx.dataset.data.reduce((accumulator, 
                currentValue) => {
                  return accumulator + currentValue
                }, 0)
                const percentage = value / totalSum * 100
                return "%" + percentage.toFixed(1)
            })
          }
        }
    };

      const ctx = document.getElementById("${CLASSID}").getContext("2d");
      new Chart(ctx, {
          type: '${CHARTTYPE.VALUE}',
          data: chartData,
          options: optionsGrafico,
          plugins: [ChartDataLabels]
      });
    </script>
  `

  htmlParts.push(chartScript)

} */

/* const dataGrouping = (data, groupingFields) => {

  const grouping = data.reduce((accumulator, item) => {
    const key = groupingFields.map(({ FIELDPATH }) => {
      if (FIELDPATH.includes(".")) {
        const parts = FIELDPATH.split(".")
        let value = item
        for (let i = 0; i < parts.length; i++) {
          value = value[parts[i]]
        }
        return value
      } else return item[FIELDPATH]
    }).join("_")

    if (!accumulator[key]) {
      accumulator[key] = {}

      groupingFields.forEach(({ FIELDPATH, DESCRIPTION }) => {
        if (FIELDPATH.includes(".")) {
          const parts = FIELDPATH.split(".")
          let value = item
          for (let i = 0; i < parts.length; i++) { value = value[parts[i]] }
          if (FIELDPATH.includes(".")) FIELDPATH = FIELDPATH.replaceAll(".", "_")
          accumulator[key][DESCRIPTION] = value
        } else accumulator[key][DESCRIPTION] = item[FIELDPATH]
      })
    }

    return accumulator
  }, {})

  return Object.values(grouping)
}

const dataAccumulator = (accumulatorsFields, dataArray) => {
  const accumulator = {}

  dataArray.forEach(item => {
    accumulatorsFields.forEach(({ FIELD, FUNCTION }) => {
      const fieldValue = item[FIELD]

      if (!accumulator[FIELD]) {
        accumulator[FIELD] = 0
      }

      switch (FUNCTION.VALUE) {
        case "count":
          if (fieldValue) {
            accumulator[FIELD]++
          }
          break
        case "avg":
          if (accumulator[`${FIELD}_sum`] === undefined) {
            accumulator[`${FIELD}_sum`] = 0
            accumulator[`${FIELD}_count`] = 0
          }
          accumulator[`${FIELD}_sum`] += fieldValue
          accumulator[`${FIELD}_count`]++
          break
        default:
          accumulator[FIELD] += fieldValue
          break
      }
    })
  })

  accumulatorsFields.forEach(({ FIELD, FUNCTION }) => {
    if (FUNCTION.VALUE === "avg") {
      if (accumulator[`${FIELD}_count`] > 0) {
        accumulator[FIELD] = accumulator[`${FIELD}_sum`] / accumulator[`${FIELD}_count`]
      } else {
        accumulator[FIELD] = 0
      }
      delete accumulator[`${FIELD}_sum`]
      delete accumulator[`${FIELD}_count`]
    }
  })

  return accumulator
} */
