import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import LoginData from "../../../../../models/loginData"
import dbRequest from "../../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../../services/showAlert"
import { defaultTheme } from "../../../../../styles/themes/default"
import * as S from "./styles"

const getTimeAgo = (date, t) => {
  const now = new Date()
  const diffInMilliseconds = now - new Date(date)
  const diffInMinutes = Math.floor(diffInMilliseconds / 1000 / 60)
  const diffInHours = Math.floor(diffInMinutes / 60)
  const diffInDays = Math.floor(diffInHours / 24)

  if (diffInMinutes < 1) {
    return t("just now")
  } else if (diffInMinutes < 60) {
    return t("about {{minutes}} minutes ago", { minutes: diffInMinutes })
  } else if (diffInHours < 24) {
    return t("about {{hours}} hours ago", { hours: diffInHours })
  } else {
    return t("about {{days}} days ago", { days: diffInDays })
  }
}

export default function NotificationItem({ notification }) {
  const { t } = useTranslation()
  const { ID } = LoginData.getLoginData()

  const [isRead, setIsRead] = useState(false)

  const handleMarkAsRead = async () => {
    if (isRead) return

    try {
      const userIdIndex = notification.RECIPIENT_IDS.findIndex(userId => userId.includes(ID + "false"))

      await dbRequest
        .updateRecords("-NOTIFICATIONS")
        .record({
          ...notification,
          RECIPIENT_IDS: notification.RECIPIENT_IDS.map((userId, index) => userIdIndex === index ? userId.replace(ID + "false", ID + "true") : userId)
        })
        .execute()
      setIsRead(true)
    } catch (error) {
      showAlert({
        title: "Erro",
        text: "Erro ao marcar a notificação como lida"
      })
    }
  }

  useEffect(() => {
    const userRead = notification.RECIPIENT_IDS.filter(userId => userId.includes(ID + "true"))

    setIsRead(userRead.length > 0)
  }, [notification])

  return (
    <S.NotificationItem
      style={isRead ? { backgroundColor: defaultTheme["gray-200"] } : {}}
      onClick={handleMarkAsRead}
    >
      {!isRead && <S.NotificationsMarker />}
      <S.NotificationTitle>{notification.TITLE}</S.NotificationTitle>
      <S.NotificationMessage>{notification.MESSAGE}</S.NotificationMessage>
      <S.NotificationDate>{getTimeAgo(notification.CREATED_AT, t)}</S.NotificationDate>
    </S.NotificationItem>
  )
}
