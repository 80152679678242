import dbRequest from "./dbRequest/dbRequest"

export default async function sendNotification({
  title,
  message,
  recipientIds,
  originScreen = "",
  type = "",
  action = {}
}) {

  try {
    // Criação do objeto da mensagem
    const messageObject = {
      TITLE: title,
      MESSAGE: message,
      RECIPIENT_IDS: recipientIds.map(recipientId => recipientId + "false"),
      ORIGIN_SCREEN: originScreen,
      TYPE: type,
      ACTION: action
    }

    // Salva a notificação no banco de dados
    await dbRequest
      .saveRecords("-NOTIFICATIONS")
      .record(messageObject)
      .execute()

    // Busca os tokens de push notification dos destinatários
    const expoPushTokens = await Promise.all(
      recipientIds.map(async (userId) => {
        const userData = await dbRequest
          .loadRecords("-USER_RELATIONSHIP")
          .admin()
          .where("ID", "==", userId)
          .execute()

        if (userData) {
          if (userData[0]?.PUSH_TOKEN) return userData[0]?.PUSH_TOKEN
          else return null
        } else return null

      })
    )

    // Filtra tokens válidos
    const validPushTokens = expoPushTokens.filter(token => token)

    // Verifica se há tokens válidos antes de enviar a notificação
    if (validPushTokens.length > 0) {
      const notificationPayload = {
        to: validPushTokens,
        sound: "default",
        title: messageObject.TITLE,
        body: messageObject.MESSAGE,
        data: messageObject
      }

      // Envia a notificação via Expo Push API
      await fetch("https://exp.host/--/api/v2/push/send", {
        method: "POST",
        mode: "no-cors",
        headers: {
          Accept: "application/json",
          "Accept-encoding": "gzip, deflate",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(notificationPayload)
      })

    } else {
      console.log("Nenhum token de push válido encontrado")
    }

  } catch (error) {
    console.error("Erro ao enviar notificação:", error)
  }
}
