import React, { createContext, useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"
import LoginData from "../models/loginData"
import dbRequest from "../services/dbRequest/dbRequest"
import monitorNotifications from "../services/dbRequest/requests/monitorNotifications"
import { defaultTheme } from "../styles/themes/default"

const NotificationContext = createContext()

export const NotificationProvider = ({ children }) => {

  const [notifications, setNotifications] = useState([])
  const [unReadNotifications, setUnReadNotifications] = useState([])
  const [updateNotifications, setUpdateNotifications] = useState(false)

  const onUpdateNotifications = () => setUpdateNotifications(s => !s)

  const handleMarkAllAsRead = async () => {
    const { ID } = LoginData.getLoginData()

    try {
      const updatedNotifications = await Promise.all(
        unReadNotifications.map(async (notification) => {
          const userIdIndex = notification.RECIPIENT_IDS.findIndex(userId => userId.includes(ID + "false"))

          await dbRequest
            .updateRecords("-NOTIFICATIONS")
            .record({
              ...notification,
              RECIPIENT_IDS: notification.RECIPIENT_IDS.map((userId, index) => userIdIndex === index ? userId.replace(ID + "false", ID + "true") : userId)
            })
            .execute()

          return {
            ...notification,
            IS_READ: true
          }
        })
      )

      setNotifications(updatedNotifications)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const handleNotifications = async (data) => {
      const { ID } = LoginData.getLoginData()

      setUnReadNotifications(data.filter(item => item.RECIPIENT_IDS.includes(`${LoginData.getLoginData()?.ID}false`)))
      setNotifications(data)

      if (data.length === 0) return

      const lastNotification = data[0]
      const hasUnreadMessages = lastNotification.RECIPIENT_IDS.some(userId => userId.includes(ID + "false"))

      if (hasUnreadMessages) toast(
        <div>
          <strong style={{
            fontSize: "14px",
            color: defaultTheme["gray-700"]
          }}>
            {lastNotification.TITLE}
          </strong>
          <div style={{
            fontSize: "12px",
            color: defaultTheme["gray-500"],
            marginTop: "5px"
          }}>
            {lastNotification.MESSAGE}
          </div>
        </div>
      )
    }

    const unsubscribe = monitorNotifications(handleNotifications)

    return () => unsubscribe()
  }, [updateNotifications])

  return (
    <NotificationContext.Provider value={{
      notifications,
      unReadNotifications,
      onUpdateNotifications,
      handleMarkAllAsRead
    }}>

      {children}
    </NotificationContext.Provider>
  )
}

export const useNotifications = () => {
  return useContext(NotificationContext)
}
