import LoginData from "../../../models/loginData"
import dbRequest from "../../dbRequest/dbRequest"

const getLauncherScreens = async ({ menuData, rootParentID = null, userPermissions, routine = null }) => {
  const isSystemAdmin = LoginData.getLoginData().ADMIN

  const filteredMenu = menuData.filter(button => {

    if (button?.isLauncher && !userPermissions[button?.ENTITYID]?.READ && button.DEPTH === 0 && !isSystemAdmin) return false
    if (button.TYPE.VALUE === "Hierarchic") button.TYPE.VALUE = "Registration"
    if (!routine || button.DESCRIPTION === "Personalized") return true
    return button.TYPE.VALUE === routine
  })

  const updatedButtons = await Promise.all(filteredMenu.map(async (button) => {

    if (button.isLauncher) return button

    // Busca os launchers associados ao botão
    const launchers = await fetchLaunchers(button, routine)

    // Aplica as permissões aos launchers
    const launchersWithPermissions = applyPermissionsFilter({
      launchers,
      userPermissions
    })

    // Mantém os SUB_MENUS que já existem e não são launchers
    let combinedSubMenus = button.SUB_MENUS || []

    // Se existirem launchers, os adiciona ao SUB_MENUS
    if (launchersWithPermissions.length > 0) {
      combinedSubMenus = [...combinedSubMenus, ...launchersWithPermissions]
    }

    // Atualiza os SUB_MENUS com novos launchers, se existirem
    button.SUB_MENUS = combinedSubMenus

    const currentRootParentID = rootParentID || button.DESCRIPTION

    // Se houver SUB_MENUS, processa-os recursivamente
    if (button.SUB_MENUS?.length > 0) {
      button.SUB_MENUS = await getLauncherScreens({
        menuData: button.SUB_MENUS,
        rootParentID: currentRootParentID,
        userPermissions,
        routine
      })
    }

    // Retorna o botão atualizado com RootParentID
    return {
      ...button,
      RootParentID: currentRootParentID
    }
  }))

  return updatedButtons
}

export default getLauncherScreens

async function fetchLaunchers(routine, routineName) {
  const { ID, TYPE } = routine

  const entities = {
    Registration: "T_DTD_TAB",
    Hierarchic: "T_DTD_TAB",
    OrderPanel: "T_DTD_TAB",
    Records: "T_DTD_TAB",
    LauncherPanel: "T_DTD_TAB",
    Dashboard: "T_RDB_DSB",
    Reports: "T_RDB_REP",
    Calendar: "T_REC_CAL"
  }

  const entity = entities[TYPE.VALUE]

  const launchers = await dbRequest
    .loadRecords(entity)
    .where("NAVID", "==", ID)
    .execute() as any

  return launchers.map(launcher => ({
    ID: launcher.ID,
    DESCRIPTION: entity === "T_DTD_TAB" ? launcher.ENTITY : launcher.DESCRIPTION,
    TYPE: entity === "T_DTD_TAB" ? launcher.TYPE : { VALUE: TYPE.VALUE },
    ORDERBY: launcher.ORDERBY,
    ONMOB: launcher?.ONMOB ?? false,
    isLauncher: true
  }))
}

function applyPermissionsFilter({ launchers, userPermissions }) {
  const isSystemAdmin = LoginData.getLoginData().ADMIN

  if (isSystemAdmin) return launchers
  return launchers.filter(({ ID }) => userPermissions[ID]?.READ)
}
