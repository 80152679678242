import styled from "styled-components"
import { defaultTheme } from "../../../../../../styles/themes/default"

export const FieldContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 45px;
`

export const FieldTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${defaultTheme["cyan-700"]};
  font-weight: lighter;
  font-size: 14px;
  margin-left: 5px;
  margin-bottom: 3px;
`

export const FieldValue = styled.div`
  padding: 5px 5px 5px 10px;
  font-size: 12px;
  color: ${defaultTheme["gray-700"]};
  background-color: ${defaultTheme["gray-200"]};
  outline: 1px solid ${defaultTheme["gray-400"]};
  border-radius: 6px;
`
