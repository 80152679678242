import styled from "styled-components"
import { defaultTheme } from "../../../../../../../styles/themes/default"

export const Container = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid ${defaultTheme["gray-300"]};
  padding-left: 10px;
  cursor: pointer;
  &:active {
    opacity: 0.6;
  }
`

export const Value = styled.label`
  width: 82%;
  color: ${defaultTheme["gray-700"]};
  font-size: 12px;
`

export const ModalContainer = styled.div`
  align-self: center;
  background-color: ${defaultTheme["gray-100"]};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 80%;
  padding: 10px 15px;
  position: relative;
  width: 80%;
`

export const Title = styled.h1`
  align-self: center;
  color: ${defaultTheme["gray-900"]};
  display: flex;
  font-size: 18px;
  font-weight: 500;
`
