import { Platform } from "react-native"
import LoginData from "../../models/loginData"
import applyMobileFilters from "./utils/applyMobileFilters"
import createSubmenus from "./utils/createSubmenus"
import fetchNavigations from "./utils/fetchNavigations"
import getLauncherScreens from "./utils/getLauncherScreens"
import getMenuMaxDepth from "./utils/getMenuMaxDepth"
import getUserPermissions from "./utils/getUserPermissions"
import removeMenusWithoutSubmenus from "./utils/removeMenusWithoutSubmenus"

export default async function menuConstructor() {
  const menuData = await fetchNavigations()
  const maxDepth = getMenuMaxDepth(menuData)
  const formattedMenu = createSubmenus({
    menuData,
    currentDepth: 0,
    maxDepth,
    parentCode: 0
  })

  const isSystemAdmin = LoginData.getLoginData().ADMIN
  const userPermissions = isSystemAdmin ? {} : await getUserPermissions()
  const processedButtons = await getLauncherScreens({
    menuData: formattedMenu,
    userPermissions
  })

  const isWeb = Platform.OS === "web"

  let clearedButtons = null
  if (isWeb) clearedButtons = removeMenusWithoutSubmenus(processedButtons)
  else clearedButtons = removeMenusWithoutSubmenus(applyMobileFilters(processedButtons))

  const homeButton = {
    ID: 0,
    DEPTH: 0,
    DESCRIPTION: "Overview",
    ICON: "home",
    TYPE: "Overview",
    RootParentID: "Overview"
  }

  clearedButtons.unshift(homeButton)

  return clearedButtons
}

