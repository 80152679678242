export default class RuntimeConfiguration {
  private static allBranches: object[]
  private static currentBranchData: object
  private static isDeveloperConfigurationMode: boolean
  private static dashboardBuilderAdminMode: boolean
  private static userMddData: object
  private static userPushToken: string

  //GETTERS
  static getUserPushToken(): string {
    return RuntimeConfiguration.userPushToken
  }

  static getAllBranches(): object[] {
    return RuntimeConfiguration.allBranches
  }

  static getCurrentBranchData(): object {
    return RuntimeConfiguration.currentBranchData
  }

  static getIsDeveloperConfigurationMode(): boolean {
    return RuntimeConfiguration.isDeveloperConfigurationMode
  }

  static getDashboardBuilderAdminMode(): boolean {
    return RuntimeConfiguration.dashboardBuilderAdminMode
  }

  static getUserMddData(): object {
    return RuntimeConfiguration.userMddData
  }

  //SETTERS
  static setUserPushToken(param: string): void {
    RuntimeConfiguration.userPushToken = param
  }

  static setAllBranches(param: object[]): void {
    RuntimeConfiguration.allBranches = param
  }

  static setCurrentBranchData(param: object): void {
    RuntimeConfiguration.currentBranchData = param
  }

  static setIsDeveloperConfigurationMode(param: boolean): void {
    RuntimeConfiguration.isDeveloperConfigurationMode = param
  }

  static setDashboardBuilderAdminMode(param: boolean): void {
    RuntimeConfiguration.dashboardBuilderAdminMode = param
  }

  static setUserMddData(param: object): void {
    RuntimeConfiguration.userMddData = param
  }
}
