import { Platform } from "react-native"
import { MultiSelect } from "react-native-element-dropdown"
import styled from "styled-components/native"
import normalize from "../../../../../../../styles/normalizeSize"
import { defaultTheme, fontFamily } from "../../../../../../../styles/themes/default"

const isWeb = Platform.OS === "web"

export const Container = styled.View`
  background-color: ${defaultTheme["white"]};
  flex-direction: row;
  align-items: center;
  margin-top: ${isWeb ? 0 : normalize(3)}px;
  height: ${isWeb ? 26 : normalize(30)}px;
  gap: ${isWeb ? 12 : normalize(12)}px;
  padding: 0 ${isWeb ? 8 : normalize(8)}px;
  border-radius: ${isWeb ? 8 : normalize(10)}px;
  border: 1px solid ${defaultTheme["gray-300"]};
`

export const Input = styled(MultiSelect).attrs(() => ({
  containerStyle: {
    marginTop: Platform.OS === "android" ? -20 : 0,
    borderRadius: normalize(6)
  },
  placeholderStyle: {
    fontSize: isWeb ? 12 : normalize(14),
    color: defaultTheme["gray-700"],
    fontFamily: isWeb ? "sans-serif" : fontFamily["medium"]
  }
}))`
  width: 100%;
  height: 100%;
  padding: 0 ${isWeb ? 5 : normalize(10)}px;
  color: ${defaultTheme["gray-700"]};
  font-size: ${isWeb ? 12 : normalize(14)}px;
  font-family: ${isWeb ? "sans-serif" : fontFamily["medium"]};
`
