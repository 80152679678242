import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import * as S from "./styles"

export default function Checkbox({ fieldId, changeFieldValue }) {

  const { t } = useTranslation()

  const [state, setState] = useState(false)

  const onChange = () => {
    setState(s => {
      changeFieldValue({
        field: fieldId,
        value: !s
      })
      return !s
    })
  }

  return (
    <S.Container onClick={onChange}>
      <S.Box isActive={state}>
        <S.Icon name='check' />
      </S.Box>

      <S.Title>{state ? t("Yes") : t("No")}</S.Title>
    </S.Container>
  )
}
