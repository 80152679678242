import { getDocs, deleteDoc, collection } from "firebase/firestore"
import { dbConnection } from "../../dbConnection"

export default async function deleteCollection(admin, _collection) {

  const collectionRef = collection(dbConnection(admin), _collection)

  const querySnapshot = await getDocs(collectionRef)

  if (querySnapshot.empty) return "Não encontrado."

  querySnapshot.forEach(async (doc) => await deleteDoc(doc.ref))

}
