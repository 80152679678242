import { DataManager, Query } from "@syncfusion/ej2-data"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import ActionButton from "../components/ActionButton"
import ColumnsManager from "../components/ColumnsManager"
import SearchInput from "../components/Inputs/Search"
import LoadingModal from "../components/LoadingModal"
import MoreOptionsButton from "../components/MoreOptionsButton"
import { toolbarIcons } from "../utils/icons"
import * as S from "./styles"

export default function KanbanToolbar({
  entity,
  models,
  functions,
  gridRef,
  screenType,
  records,
  setFilteredRecords
}) {
  const { t } = useTranslation()

  const [loadingModalVisible, setLoadingModalVisible] = useState(false)
  const [searchText, setSearchText] = useState("")

  const { handleInsert, handleCopy, handleDelete, handleRefresh } = functions
  const { permissions, type } = models._REGISTRATION_DATA[entity]
  const { CREATE, DELETE } = permissions
  const { changeView } = functions

  const handleSearch = (e) => {
    const searchValue = e.target.value
    setSearchText(searchValue)

    if (searchValue !== "") {
      const dataManager = new DataManager(records)

      const query = new Query().search(
        searchValue,
        ["ID", "DESCRIPTION"],
        "contains",
        true
      )

      const filtered = dataManager.executeQuery(query)

      filtered.then((result) => {
        setFilteredRecords(result.result)
      })
    } else {
      setFilteredRecords(records)
    }
  }

  const clearInput = () => {
    setSearchText("")
    setFilteredRecords(records)
  }

  return (
    <S.Container>
      <S.ItemsContainer>
        {handleInsert && <ActionButton
          action={() => {
            handleInsert({ changeLoadingModalVisibility: () => setLoadingModalVisible(s => !s) })
          }}
          disabled={!CREATE}
          text={t("Add new record")}
          renderText
          icon={toolbarIcons.add}
        />}

        {handleCopy && <ActionButton
          action={handleCopy}
          disabled={!CREATE}
          text={t("Copy record")}
          icon={toolbarIcons.copy}
        />}

        {handleDelete && <ActionButton
          action={async () => {
            await handleDelete({
              changeLoadingModalVisibility: () => setLoadingModalVisible(s => !s),
              updateGrid: handleRefresh
            })
          }}
          disabled={!DELETE}
          text={t("Delete record")}
          icon={toolbarIcons.delete}
        />}

        {handleRefresh && <ActionButton
          action={() => handleRefresh({ changeLoadingModalVisibility: () => setLoadingModalVisible(s => !s) })}
          text={t("Refresh records")}
          icon={toolbarIcons.refresh}
        />}

        {changeView && <ActionButton
          action={changeView}
          text="Kanban"
          icon={toolbarIcons.kanban}
        />}
      </S.ItemsContainer>

      <S.ItemsContainer>
        <S.ContainerSearchInput>
          <S.Input
            placeholder="Search"
            value={searchText}
            onChange={handleSearch}
            autoFocus={screenType === "searchModal"}
          />
          <S.Button onClick={clearInput}>
            <S.Icon name={searchText ? "close" : "search1"} />
          </S.Button>
        </S.ContainerSearchInput>
      </S.ItemsContainer>

      <S.ItemsContainer>
        <MoreOptionsButton entity={entity} models={models} gridRef={gridRef} setUpdateForm={() => null} />
      </S.ItemsContainer>

      <LoadingModal isVisible={loadingModalVisible} />
    </S.Container>
  )
}
