interface ObjectData {
  ID: string
  LABEL: string
}

interface PhotoData {
  NAME: string
  SIZE: number
  TYPE: string
  URL: string
}

interface UserData {
  ACTIVE: boolean
  ADMIN: boolean
  COMPANYID: ObjectData
  DEV: boolean
  DESCRIPTION: string
  EMAIL: string
  EXPORT_REPORTS?: boolean | undefined
  GROUPUID: ObjectData
  ID: string
  IMG: Array<PhotoData>
  PARTNERID: ObjectData
  PHOTO: Array<PhotoData>
  RESTRICT_PARTNERS: boolean
  SESSIONID: string
  SUPERIORUID: ObjectData
  T_SET_ACC: object
  USERNAME: string
  FIRSTNAME: string
  LASTNAME: string
  PHONENUMBER: string
  ROLE: string
  CITY: ObjectData
  BACKGROUND: {
    NAME: string
    SIZE: number
    TYPE: string
    URL: string
  }
  _MANUALID: boolean
  PUSH_TOKEN: string
}

export default class LoginData {
  static LoginData: UserData

  static getLoginData(): UserData { return this.LoginData }

  static setLoginData(userData: UserData): void { this.LoginData = userData }

  static clear(): void { delete this.LoginData }
}
