import React, { useEffect, useState } from "react"
import * as S from "./styles"

export default function Decimal({ fieldId, fieldValue, changeFieldValue, selectedUnit, handleCalculation }) {

  if (typeof fieldValue !== "number") fieldValue = 0

  const [state, setState] = useState(fieldValue)

  const prefix = fieldId === "DISCOUNTPERC" ? "% " : ""

  const changeValue = (value) => {
    if (fieldId === "DISCOUNTPERC") {
      return handleCalculation("change-discount-percentage", {
        value: value || 0,
        fieldId
      })
    } else {
      changeFieldValue({
        value: value,
        field: fieldId
      })
    }
  }

  useEffect(() => {
    setState(fieldValue)
  }, [fieldValue])

  return (
    <S.Container>
      <S.Input
        value={state}
        placeholder={"0,00"}
        precision={!isNaN(selectedUnit?.VALUEDEC) ? selectedUnit?.VALUEDEC ?? 2 : 2}
        isNegative={state < 0}
        onChangeValue={r => {
          setState(r)
          changeValue(r)
        }}
        prefix={prefix}
      />
    </S.Container>
  )
}
