import styled from "styled-components"
import { defaultTheme } from "../../../../styles/themes/default"

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  padding-left: 8px;
`

export const Button = styled.button`
  display: flex;
  align-items: flex-end;
  padding: 4px 8px;
  gap: 5px;
  color: ${defaultTheme["gray-700"]};
  background-color: ${defaultTheme["gray-100"]};
  border-radius: 5px;
  box-shadow: 0px 4px 8px ${defaultTheme["shadow"]};
  font-size: 14px;
  font-weight: 500;

  &:active {
    opacity: 0.8;
  }
`

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: auto;
  background-color: ${defaultTheme["gray-200"]};
  overflow: hidden;
  border-radius: 20px;
`

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20%;
  padding: 0px 50px 0px 50px;
`

export const ModalTitle = styled.h1`
  color: ${defaultTheme["gray-700"]};
  font-size: 22px;
  font-weight: 600;
  margin: 0px;
`

export const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  & > *:nth-child(3) {
    position: relative;
    left: 30px;
  }
`

export const ApplyButton = styled.button`
  padding: 4px 6px;
  background-color: ${defaultTheme["white"]};
  border-radius: 14px;
  color: ${defaultTheme["white"]};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`

export const ClearButton = styled(ApplyButton)`
  background-color: ${defaultTheme["white"]};
`

export const TitleButton = styled.h1`
  font-size: 12px;
  font-weight: 400;
  padding-right: 4px;
  color: ${defaultTheme["gray-600"]};
`

export const Icon = styled.div`
  background-color: ${defaultTheme["cyan-300"]};
  border-radius: 50%;
  margin-right: 2px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CloseButton = styled.a`
  display: flex;
  justify-content: center;
  cursor: pointer;
`

export const FormView = styled.div`
  overflow: scroll;
  overflow-x: hidden;
  width: calc(100% + 16px);
`
