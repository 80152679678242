import { Ionicons } from "@expo/vector-icons"
import styled from "styled-components"
import globalStyles from "../../../../global/globalStyles"
import { defaultTheme } from "../../../../styles/themes/default"

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const ButtonsView = styled.div`
  position: absolute;
  top: ${({ isVisible }) => isVisible ? "12px" : "2px"};
  right: 0px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding-right: 10px;
`

export const Loading = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid ${globalStyles.colors.primary};
  border-top: 5px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`

export const LoadingView = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`

export const TextView = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: 2px;
`

export const Icon = styled(Ionicons).attrs({ name: "information-circle-outline" })`
  color: ${globalStyles.colors.primary};
  font-size: 16px;
`

export const Text = styled.span`
  color: ${globalStyles.colors.primary};
  font-size: 14px;
`

export const Title = styled.h1`
  font-family: Arial, sans-serif;
  color: #333333;
  font-size: 18px;
  font-weight: 600;
  margin-left: 5px;
`

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  padding: 3px;
  margin-top: 5px;
  background-color: ${globalStyles.colors.tertiary};
  border-radius: 5px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: scale(1.05);
  }

  z-index: 2;
`

export const ContainerButton = styled.button`
  position: absolute;
  top: 111%;
  right: 3%;
  align-items: center;
  background-color: ${defaultTheme["gray-200"]};
  border-radius: 9px;
  cursor: pointer;
  display: flex;
  gap: 5px;
  justify-content: center;
  padding: 2px 9px 2px 2px;
  pointer-events: "all";
  transition: opacity 0.2s;

  &:active {
    opacity: 0.3;
  }
`

export const PivotButton = styled.div`
   background-color: ${defaultTheme["cyan-300"]};
  border-radius: 50%;
  display: flex;
  height: 24px;
  justify-content: center;
  align-items: center;
  width: 24px;
  pointer-events: "all";
  transition: opacity 0.2s;

  &:active {
    opacity: 0.3;
  }
`

export const ButtonText = styled.p`
  color: ${defaultTheme["gray-700"]};
  font-size: 0.78rem;
  margin-left: 5;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 80px;
  width: 100%;
  padding: 10px;
`
