import {
  PivotViewComponent,
  Inject,
  FieldList,
  CalculatedField,
  Toolbar,
  PDFExport,
  ExcelExport,
  ConditionalFormatting,
  NumberFormatting,
  GroupingBar,
  VirtualScroll,
  DrillThrough,
  Grouping
} from "@syncfusion/ej2-react-pivotview"
import React, { useEffect, useState } from "react"
import "./style.css"

export default function PivotChart({ dashboardData, dataId, chartData }) {
  const [formattedData, setFormattedData] = useState([])
  const data = dashboardData[dataId] || []
  const toolbarOptions = [
    "Grid",
    "Chart",
    "Export",
    "SubTotal",
    "GrandTotal",
    "Formatting",
    "FieldList"
  ]

  useEffect(() => {
    setFormattedData(data.map(transformRecord))
  }, [])

  function transformRecord(record) {
    return Object.entries(record).reduce((acc, [key, value]) => {
      if (typeof value === "object" && value && !Array.isArray(value)) {
        Object.entries(value).forEach(([subKey, subValue]) => {
          if (subKey !== "IMG") acc[`${key}_${subKey.toUpperCase()}`] = subValue
        })
      } else {
        acc[key] = value
      }
      return acc
    }, {})
  }

  function fetchReport(args) {
    if (!localStorage.pivotviewReports) return
    args.reportName = JSON.parse(localStorage.pivotviewReports).map(
      (r) => r.reportName
    )
  }

  function beforeToolbarRender(args) {
    args.customToolbar.splice(6, 0, { type: "Separator" })
    args.customToolbar.splice(9, 0, { type: "Separator" })
  }

  function processPivotData(chartData) {
    if (!chartData) {
      return {
        values: [],
        rows: []
      }
    }

    const dataArray = Array.isArray(chartData) ? chartData : [chartData]

    return dataArray.reduce(
      (acc, item) => {
        const values = (item?.ACCUMULATOR ?? []).map(acc => ({
          name: acc?.ID ?? "",
          caption: acc?.FIELDDESCRIPTION ?? ""
        }))

        const rows = (item?.GROUPING ?? []).map(group => ({
          name: group?.ID ?? "",
          caption: group?.FIELDDESCRIPTION ?? "",
          expandAll: true,
          allowDragAndDrop: false
        }))

        acc.values.push(...values)
        acc.rows.push(...rows)
        return acc
      },
      {
        values: [],
        rows: []
      }
    )
  }

  const { values, rows } = processPivotData(chartData ?? [])

  return (
    <div className="control-pane">
      <div className="control-section" style={{ overflow: "initial" }}>
        <PivotViewComponent
          id={`PivotView${dataId}`}
          dataSourceSettings={{
            rows: rows,
            values: values,
            dataSource: formattedData,
            expandAll: false,
            enableSorting: true,
            allowLabelFilter: true,
            allowValueFilter: true
          }}
          showFieldList={true}
          exportAllPages={true}
          showGroupingBar={true}
          allowGrouping={true}
          enableVirtualization={true}
          enableValueSorting={true}
          allowDeferLayoutUpdate={true}
          allowDrillThrough={true}
          allowExcelExport={true}
          allowNumberFormatting={true}
          allowConditionalFormatting={true}
          allowPdfExport={true}
          showToolbar={true}
          allowCalculatedField={true}
          displayOption={{ view: "Both" }}
          toolbar={toolbarOptions}
          fetchReport={fetchReport}
          toolbarRender={beforeToolbarRender}
          enableFieldSearching
          minHeight="900px"
        >
          <Inject
            services={[
              FieldList,
              CalculatedField,
              Toolbar,
              PDFExport,
              ExcelExport,
              ConditionalFormatting,
              NumberFormatting,
              GroupingBar,
              Grouping,
              VirtualScroll,
              DrillThrough
            ]}
          />
        </PivotViewComponent>
      </div>
    </div>
  )
}
