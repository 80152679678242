import styled from "styled-components"
import { defaultTheme } from "../../../../../../../styles/themes/default"

export const Container = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid ${defaultTheme["gray-300"]};
`

export const Input = styled.select`
  background-color: ${defaultTheme["white"]};
  color: ${defaultTheme["gray-700"]};
  font-size: ${defaultTheme.formInput.fontSize}px;
  border-style: none;
  border-radius: 10px;
  padding: 0 12px 0 26px;
  height: 100%;
  width: 100%;
  outline: none;
  cursor: pointer;
  &:active {
    opacity: 0.6;
  }
  `

export const Option = styled.option``
