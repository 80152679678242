import styled from "styled-components"
import { defaultTheme } from "../../../../../../../styles/themes/default"

export const Container = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid ${defaultTheme["gray-300"]};
`

export const Input = styled.input`
  background-color: ${defaultTheme["white"]};
  color: ${defaultTheme["gray-700"]};
  font-size: 12px;
  padding-left: 3px;
  height: 100%;
  outline: none;

  border-style: none;

  ::placeholder {
    color: ${defaultTheme["gray-300"]};
  }
`
