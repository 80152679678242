import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import Navigation from "../../../models/navigation"
import Observable from "../../../models/Observable"
import MenuDrawer, { menuDrawerToggleObserver } from "../MenuDrawer/index.web"
import TranslationModal from "../TranslationModal"
import SuggestionsList from "./components/SuggestionsList"
import TabsContainer from "./components/TabsContainer"
import * as S from "./styles.web"
import getRoutines from "./utils/getRoutines"
import handleNavigation from "./utils/handleNavigation"

type HeaderProps = {
  navigation: any
}

export const headerObserver = new Observable()

const Header: React.FC<HeaderProps> = (props) => {
  const { t } = useTranslation()

  Navigation.setNavigation(props.navigation)

  const inputRef = useRef<HTMLInputElement>(null)

  const [isInputFocused, setIsInputFocused] = useState(false)
  const [textInput, setTextInput] = useState("")
  const [selectedTabName, setSelectedTabName] = useState("Overview")
  const [routines, setRoutines] = useState<any[]>([])
  const [update, setUpdate] = useState<Record<string, any>>({})
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0)

  useEffect(() => {
    getRoutines().then(setRoutines)
  }, [update])

  headerObserver.unsubscribeAll()
  headerObserver.subscribe(() => setUpdate((s) => !s))

  const lowercasedInput = textInput.toLowerCase()
  const validateValue = (value: string | undefined) =>
    value && t(value).toLowerCase()
      .includes(lowercasedInput)

  const filteredRoutines =
    textInput === ""
      ? []
      : routines.filter(
        ({ ID, NAVID, TYPE, DESCRIPTION }) =>
          validateValue(ID) ||
          validateValue(NAVID) ||
          validateValue(TYPE?.VALUE) ||
          validateValue(DESCRIPTION)
      )

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Home") {
      event.preventDefault()
      setSelectedTabName("Overview")
      props.navigation.navigate("Overview")
    } else if (event.key === "ArrowDown") {
      event.preventDefault()
      setSelectedSuggestionIndex((prevIndex) =>
        Math.min(prevIndex + 1, filteredRoutines.length - 1)
      )
    } else if (event.key === "ArrowUp") {
      event.preventDefault()
      setSelectedSuggestionIndex((prevIndex) => Math.max(prevIndex - 1, 0))
    } else if (event.key === "Enter" && filteredRoutines.length > 0) {
      handleRoutineClick(filteredRoutines[selectedSuggestionIndex])
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextInput(e.target.value)
    setSelectedSuggestionIndex(0)
  }

  const handleInputClick = () => {
    setIsInputFocused(true)
    inputRef.current?.focus()
    setSelectedSuggestionIndex(0)
  }

  const handleRoutineClick = (routine: any) => {
    handleNavigation(routine)
    setTextInput("")
    setIsInputFocused(false)
    setSelectedSuggestionIndex(0)
  }

  useEffect(() => {
    const handleShortcut = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key.toLowerCase() === "s") {
        menuDrawerToggleObserver.notify()
        event.preventDefault()
        setIsInputFocused((prevState) => !prevState)
        if (!isInputFocused) {
          inputRef.current?.focus()
        } else {
          inputRef.current?.blur()
        }
      }
    }

    document.addEventListener("keydown", handleShortcut)
    return () => document.removeEventListener("keydown", handleShortcut)
  }, [isInputFocused])

  return (
    <S.Container>
      <MenuDrawer
        selectedTabName={selectedTabName}
        setSelectedTabName={setSelectedTabName}
      />
      <TabsContainer {...props} setSelectedTabName={setSelectedTabName} />

      <S.Content>
        <S.SearchContainer
          isInputFocused={isInputFocused}
          onClick={handleInputClick}
        >
          <S.Icon />

          <S.Input
            ref={inputRef}
            isInputFocused={isInputFocused}
            value={textInput}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => textInput === "" && setIsInputFocused(false)}
            placeholder={t("Search routines")}
          />

          {isInputFocused && filteredRoutines.length > 0 && (
            <SuggestionsList
              suggestions={filteredRoutines}
              onSelectSuggestion={handleRoutineClick}
              selectedIndex={selectedSuggestionIndex}
            />
          )}
        </S.SearchContainer>

        <TranslationModal />

        <S.BranchLogo src={require("../../../../assets/musa_logo_clara.png")} />
      </S.Content>
    </S.Container>
  )
}

export default Header
