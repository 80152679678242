import React, { useState } from "react"
import validateDate from "../../../../../../../services/validateDate"
import * as S from "./styles"

export default function DateInput({
  fieldId,
  fieldValue,
  changeFieldValue
}) {

  const [state, setState] = useState(() => {
    if (typeof fieldValue === "string") return fieldValue
    else return ""
  })

  const onBlur = () => {
    let isDateValid = true
    isDateValid = validateDate(state)

    if (!isDateValid) {
      setState(null)
      changeFieldValue({
        field: fieldId,
        value: null
      })
      return
    }

    const formatDate = str => {
      if (str === "") return null

      const [date, time] = str.split("-")
      const [day, month, year] = date.split("/")
      const formattedDate = `${year?.trim()}-${month?.trim()}-${day?.trim()}`

      return `${formattedDate}${time ? time?.trim() : "T00:00:00"}`
    }

    const value = formatDate(state)

    changeFieldValue({
      field: fieldId,
      value: value ? new globalDate(value).toISO() : null
    })
  }
  return (
    <S.Container>
      <S.Input
        type="date"
        placeholder={"DD/MM/YYYY"}
        value={state}
        onChange={e => setState(e.target.value)}
        onBlur={onBlur}
      />
    </S.Container>
  )
}
