import { collection, query, where, onSnapshot } from "firebase/firestore"
import RuntimeConfiguration from "../../../../models/runtimeConfiguration"
import { dbConnection } from "../../dbConnection"

export default function monitorNotifications(onNotificationsUpdate) {
  const userID = RuntimeConfiguration.getUserMddData()?.ID
  if (!userID) {
    console.log("ID do usuário não encontrado")
    return () => null
  }

  const notificationsRead = userID + "true"
  const notificationsUnread = userID + "false"

  const q = query(collection(dbConnection(false), "-NOTIFICATIONS"), where("RECIPIENT_IDS", "array-contains-any", [notificationsRead, notificationsUnread]))

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const notifications = []

    querySnapshot.forEach((documentSnapshot) => {
      notifications.push({
        ID: documentSnapshot.id,
        ...documentSnapshot.data()
      })
    })

    notifications.sort((a, b) => new Date(b.CREATED_AT) - new Date(a.CREATED_AT))
    onNotificationsUpdate(notifications)
  }, (error) => {
    console.error("Erro ao monitorar a coleção NOTIFICATIONS:", error)
  })

  return unsubscribe
}
