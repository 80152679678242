import React, { useState } from "react"
import * as S from "./styles"

export default function Hours({ fieldId, fieldValue, changeFieldValue }) {

  const [state, setState] = useState(() => {
    if (typeof fieldValue === "string") return fieldValue
    else return ""
  })

  const onBlur = () => {
    if (state && state.includes(":")) {
      changeFieldValue({
        field: fieldId,
        value: timeToMinutes(state)
      })
    } else {
      changeFieldValue({
        field: fieldId,
        value: null
      })
    }
  }

  return (
    <S.Container>
      <S.Input
        value={state}
        type="datetime"
        placeholder="00:00"
        onBlur={onBlur}
        onChangeText={setState}
      />
    </S.Container>
  )
}

function timeToMinutes(time) {
  const [hours, minutes] = time.split(":")
  return Number(hours) * 60 + Number(minutes)
}
