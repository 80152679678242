import dbRequest from "../../../../services/dbRequest/dbRequest"
import detailTabHasRecords from "../../../../services/registration/detailTabHasRecords"
import foreignKeyValidation from "../../../../services/registration/foreignKeyValidation"
import runTriggers from "../../../../services/registration/runTriggers"
import showAlert from "../../../../services/showAlert"
import { gridObserver } from "../../Grid"
import hierarchicalRecordValidation from "../../ScreenFactory/utils/ButtonsFunctions/utils/delete/hierarchicalRecordValidation"

const handleKanbanDelete = ({
  models,
  entity,
  changeLoadingModalVisibility,
  selectedCard,
  setSelectedCard,
  setUpdate
}) => {

  if (!selectedCard) {
    return showAlert({
      text: "No record selected",
      title: "Failed to delete",
      titleType: "error"
    })
  }
  showAlert({
    text: "Do you want to delete the selected record?",
    type: "confirm",
    titleType: "question",
    handleConfirm: async () => {
      const isDeleted = await deleteRecord({
        models,
        entity,
        record: selectedCard,
        changeLoadingModalVisibility
      })

      if (isDeleted) {
        setSelectedCard(null)
        setTimeout(() => {
          setUpdate(s => !s)
        }, 2000)
      }
    }
  })
}

async function deleteRecord({
  models,
  entity,
  record,
  changeLoadingModalVisibility
}) {
  changeLoadingModalVisibility()
  const tableData = models._REGISTRATION_DATA[entity]

  tableData.operation = "DEL"

  tableData.state = "BEFORE"
  models[entity].reset()
  models[entity].populate(record)

  const isForeignKey = await foreignKeyValidation({
    entity,
    record
  })
  if (isForeignKey) {
    changeLoadingModalVisibility()
    showAlert({
      text: "Foreign key constraint",
      title: "Failed to delete",
      titleType: "warning"
    })
    return false
  }

  if (tableData.type === "Hierarchic") {
    const hasChildRecords = await hierarchicalRecordValidation(entity, record)
    if (hasChildRecords) {
      changeLoadingModalVisibility()
      showAlert({
        text: "The record has child records",
        title: "Failed to delete",
        titleType: "warning"
      })
      return false
    }
  }

  const allTriggersValid = await runTriggers(models, entity)
  if (!allTriggersValid) {
    changeLoadingModalVisibility()
    showAlert({
      text: "Triggers failed",
      title: "Failed to delete",
      titleType: "warning"
    })
    return false
  }

  const hasDetailRecords = await detailTabHasRecords({
    entity,
    models
  })
  if (hasDetailRecords) {
    changeLoadingModalVisibility()
    showAlert({
      text: "Cannot delete record with detail records",
      title: "Failed to delete",
      titleType: "warning"
    })
    return false
  }

  await dbRequest.deleteRecords(entity).record(record)
    .execute()
  tableData.state = "AFTER"

  gridObserver.notify()
  await runTriggers(models, entity)

  tableData.currentRecordIndex = null
  changeLoadingModalVisibility()

  showAlert({
    text: "Selected record has been deleted",
    title: "Record deletion",
    titleType: "success"
  })
  return true

}

export default handleKanbanDelete
