import { createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth"
import { httpsCallable } from "firebase/functions"
import RuntimeConfiguration from "../../models/runtimeConfiguration"
import removeFieldsFromObject from "../removeFieldsFromObject"
import { authRemote, remoteFunctions } from "./dbConnection/"
import dbRequest from "./dbRequest"

interface UserProps {
  name: string
  email: string
  isActive: boolean
}

export default class User {
  static async create({ name, email, isActive }: UserProps) {
    const hasMaxActiveUsersReached = await this.maxActiveUsersReached()
    if (hasMaxActiveUsersReached) return null

    const userID = await this.findOrCreateUser(email)

    await this.saveToMDD(userID, name, isActive)
    await this.resetPassword(email)

    return userID
  }

  private static async findOrCreateUser(email: string) {
    try {
      return await this.findExistingUser(email)
    } catch (error) {
      const password = this.generatePassword(15)
      const userCredential = await createUserWithEmailAndPassword(authRemote, email, password)

      return userCredential.user.uid
    }
  }

  static generatePassword(passwordSize: number) {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()-_"
    let password = ""

    for (let i = 0; i < passwordSize; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length)
      password += charset[randomIndex]
    }

    return password
  }

  static async maxActiveUsersReached() {
    const currentActiveUsers = await this.getCurrentActiveUsers()
    const maxUsers = await this.getMaxActiveUsers()

    return currentActiveUsers >= maxUsers
  }

  static async findExistingUser(email: string) {
    const UIDFunction = httpsCallable(remoteFunctions, "getUserID")
    const result = await UIDFunction({ email: email }) as { data: { uid: string } }

    return result.data.uid
  }

  static async getCurrentActiveUsers() {
    let currentActiveUsers = await dbRequest
      .loadRecords("T_SET_RLU")
      .where("ACTIVE", "==", true)
      .execute() as any

    currentActiveUsers = currentActiveUsers.filter(user => !user.DEV)

    return currentActiveUsers.length
  }

  static async getMaxActiveUsers() {
    const branchData = await dbRequest
      .loadRecords("-BRANCHES").admin()
      .where("ID", "==", RuntimeConfiguration.getCurrentBranchData().ID)
      .execute()

    if (branchData) return branchData[0].MAXUSERS
    else return 0
  }

  static async saveToMDD(userID, username, active) {
    const branchID = RuntimeConfiguration.getCurrentBranchData().ID

    let current = await dbRequest
      .loadRecords("-USER_RELATIONSHIP")
      .admin()
      .where("ID", "==", userID)
      .execute() as { ID: string, NAME: string, BRANCHID: object }

    if (!current)
      current = {
        ID: "",
        NAME: "",
        BRANCHID: {}
      }
    else current = current[0]

    current.ID = userID
    current.NAME = username
    current.BRANCHID[branchID] = active

    return await dbRequest
      .saveRecords("-USER_RELATIONSHIP")
      .admin()
      .record(removeFieldsFromObject({ ...current }, ["CREATED_BY", "CREATED_AT", "UPDATED_BY", "UPDATED_AT"]))
      .execute(r => r.ID)
  }

  static async disableUser(uid: string) {
    const branchID = RuntimeConfiguration.getCurrentBranchData().ID

    const current = await dbRequest
      .loadRecords("-USER_RELATIONSHIP")
      .admin()
      .where("ID", "==", uid)
      .execute(r => r[0]) as { BRANCHID: object }

    current.BRANCHID[branchID] = false

    await dbRequest
      .saveRecords("-USER_RELATIONSHIP")
      .admin()
      .record(removeFieldsFromObject({ ...current }))
      .execute()

    // await dbRequest
    //   .saveRecords("T_SET_RLU")
    //   .record({
    //     ID: uid,
    //     ACTIVE: false
    //   })
    //   .execute()
  }

  static async enableUser(uid: string) {

    if (await this.maxActiveUsersReached()) return false

    const branchID = RuntimeConfiguration.getCurrentBranchData().ID

    const current = await dbRequest
      .loadRecords("-USER_RELATIONSHIP")
      .admin()
      .where("ID", "==", uid)
      .execute(r => r[0]) as { BRANCHID: object }

    current.BRANCHID[branchID] = true

    await dbRequest
      .saveRecords("-USER_RELATIONSHIP")
      .admin()
      .record(removeFieldsFromObject({ ...current }))
      .execute()

    // await dbRequest
    //   .saveRecords("T_SET_RLU")
    //   .record({
    //     ID: uid,
    //     ACTIVE: true
    //   })
    //   .execute()
    return true
  }

  static async resetPassword(email) {
    await sendPasswordResetEmail(authRemote, email)
  }
}
