import showAlert from "../../../../services/showAlert"

const handleKanbanCopy = ({
  models,
  entity,
  selectedCard,
  toggleMode,
  setUpdateForm
}) => {
  const tableData = models._REGISTRATION_DATA[entity]

  if (!selectedCard) {
    return showAlert({
      text: "No record selected",
      title: "Failed to duplicate",
      titleType: "error"
    })
  }

  const callback = () => {
    tableData.currentRecordIndex = null
    setUpdateForm((s) => !s)
  }

  copyRecord({
    record: { ...selectedCard },
    models,
    entity,
    callback,
    toggleMode
  })
}

export default handleKanbanCopy

function copyRecord({ record, models, entity, callback, toggleMode }) {
  showAlert({
    type: "confirm",
    title: "Record duplication",
    text: "Do you want to duplicate this record?",
    handleConfirm: () => {
      const fieldsToDelete = [
        "ID",
        "CREATED_BY",
        "CREATED_AT",
        "UPDATED_BY",
        "UPDATED_AT"
      ]
      const tableData = models._REGISTRATION_DATA[entity]

      for (const field of fieldsToDelete) {
        record[field] = null
      }

      const { fields } = tableData

      for (const field of fields) {
        const { ID, FIELDTYPE } = field

        if (FIELDTYPE.VALUE === "A") {
          delete record[ID]
          delete tableData.files[ID]
        }
      }

      tableData.operation = "INS"
      tableData.originalRecord = { ...record }
      models[entity].populate({ ...record })

      callback()
      toggleMode()
    }
  })
}
