import { Platform } from "react-native"
import PriceTableData from "../../models/priceTableData"
import dbRequest from "../../services/dbRequest/dbRequest"
import _id from "../../services/dbRequest/requests/getDocumentId"
import evaluateFunction from "../../services/evaluateFunction"
import formValidation from "../../services/formValidation"
import removeFieldsFromObject from "../../services/removeFieldsFromObject"
import showAlert from "../../services/showAlert"
import { gridObserver } from "../../views/components/Grid"

export default class ProductsController {

  constructor(params) {

    this.OrderComposerController = params.orderComposerController

    this.modelsTemplate = params.models()
    this.models = () => this.modelsTemplate

    this.saved = params.saved
    this.screenName = params.name
    this.collectionName = params.collectionName
    this.ItemsHeaderFields = params.ItemsHeaderFields
    this.customFields = params.customFields

    this.MOVITEAttributes = null

    this.itemsHeaderStructure = () => {
      const fields = params.ItemsHeaderFields
      this.MOVITEAttributes = fields
      const formatedObj = {}
      fields.forEach(field => {
        if (field.ID === "AMOUNTNEG") return
        if (["C", "I", "F"].includes(field.FIELDTYPE.VALUE)) formatedObj[field.ID] = field?.T_DTD_ATR?.DEFAULTVALUE || 0
        else formatedObj[field.ID] = field?.T_DTD_ATR?.DEFAULTVALUE || ""
      })

      if (!formatedObj?.QUANTITY) formatedObj.QUANTITY = 0

      return formatedObj
    }

    this.taxes = this.getTAX()
    this.promotions = this.getPromotions()
  }

  listEntityName = () => "T_REC_ITE"

  async getRecords() {

    const filtersArray = await this.getFilters()

    let itemsId = await PriceTableData.getPRICETABLEDATA()

    if (typeof itemsId !== "object" || itemsId === null) {
      showAlert({
        type: "ok",
        text: "Incorrectly configured pricing",
        title: "Pricing error",
        titleType: "error",
        handleConfirm: async () => {
          this.models().T_MOV_HDR.reset()
          this.models().T_MOV_ITE = []

          this.OrderComposerController.initialize()
          this.OrderComposerController.jumpScreenStatus = false
          this.OrderComposerController.setScreens([])

          this.navigation.navigate("Transaction")

        },
        handleReject: () => null
      })

      return []
    }

    let request = dbRequest.loadRecords(this.listEntityName())

    itemsId = Object.keys(itemsId).sort((a, b) => a.localeCompare(b))

    filtersArray.push({
      field: "ID",
      operator: "array-contains",
      value: itemsId
    })

    const records = await request
      .execute(responseData => this.applyDefaultFilters(responseData, filtersArray))

    this.recordsList = records

    return records
  }

  getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj)
  }

  applyDefaultFilters = (data, filters) => {

    const finalFilteredArray = data.filter(record => {
      return filters.every(({ field, operator, value }) => {
        const fieldValue = this.getNestedValue(record, field)
        switch (operator) {
          case "==":
            return fieldValue == value
          case "!=":
            return fieldValue != value
          case ">":
            return fieldValue > value
          case ">=":
            return fieldValue >= value
          case "<":
            return fieldValue < value
          case "<=":
            return fieldValue <= value
          case "array-contains":
            return Array.isArray(value) && value.includes(fieldValue)
          default:
            return false
        }
      })
    })

    if (finalFilteredArray.length === 0) {
      showAlert({
        title: "No records found",
        text: "There are no records to select.",
        type: "ok",
        titleType: "info"
      })
    }
    if (finalFilteredArray.length === 1 && this.listEntityName() === "T_SET_TNS") {

      this.OrderComposerController.changeScreen(finalFilteredArray[0], this, () => null)
      return []
    } else return finalFilteredArray

  }

  async startChangeScreen(action, setIsLoading, navigation) {

    if (action === "cancel") {
      if (this.models().T_MOV_HDR.ID) {
        showAlert({
          type: "confirm",
          text: "Do you want to cancel editing?",
          title: "Edition cancellation",
          titleType: "warning",
          handleConfirm: () => navigation.navigate("Order Summary"),
          handleReject: () => null
        })
      } else {
        showAlert({
          type: "confirm",
          text: "Do you want to cancel the order and start a new one?",
          title: "Order cancellation",
          titleType: "warning",
          handleConfirm: async () => {
            this.models().T_MOV_HDR.reset()
            this.models().T_MOV_ITE = []

            this.OrderComposerController.initialize()
            this.OrderComposerController.jumpScreenStatus = false
            this.OrderComposerController.setScreens([])

            navigation.navigate("Transaction")
          },
          handleReject: () => null
        })
      }
    } else {
      return this.OrderComposerController.changeScreen({ toSave: true }, this, setIsLoading)
    }
  }

  async saveValues(props) {

    if (!props?.toSave) return

    if (this.models().T_MOV_HDR.ID) {

      await dbRequest
        .updateRecords("T_MOV_HDR")
        .record(removeFieldsFromObject({ ...this.models().T_MOV_HDR }))
        .execute()

      await dbRequest
        .deleteCollection(`T_MOV_HDR/${this.models().T_MOV_HDR.ID}/T_MOV_ITE`)
        .execute()

      for (let record of this.models().T_MOV_ITE) {

        let newRecord = removeFieldsFromObject({ ...record })

        await dbRequest
          .saveRecords(`T_MOV_HDR/${this.models().T_MOV_HDR.ID}/T_MOV_ITE`)
          .record({ ...newRecord })
          .execute(() => {
            this.saveTaxSubCollection(newRecord)
          })

        record = newRecord
      }
      gridObserver.notify()
    } else {

      await dbRequest
        .saveRecords("T_MOV_HDR")
        .record(removeFieldsFromObject({ ...this.models().T_MOV_HDR }))
        .execute(r => { this.models().T_MOV_HDR.ID = r.ID })

      for (let record of this.models().T_MOV_ITE) {
        let newRecord = removeFieldsFromObject({ ...record })
        await dbRequest
          .saveRecords(`T_MOV_HDR/${this.models().T_MOV_HDR.ID}/T_MOV_ITE`)
          .record({ ...newRecord })
          .execute(async () => {
            await this.saveTaxSubCollection(newRecord)
          })

        record = newRecord
      }
      gridObserver.notify()
    }
  }

  async saveTaxSubCollection(record) {
    await dbRequest
      .deleteCollection(`T_MOV_HDR/${this.models().T_MOV_HDR.ID}/T_MOV_ITE/${record.ID}/T_MOV_TAX`)
      .execute()

    for (const tax in this.models().T_MOV_TAX) {
      const newObj = removeFieldsFromObject({ ...this.models().T_MOV_TAX[tax].T_MOV_TAX_ITEMS })
      newObj.ID = tax

      await dbRequest
        .saveRecords(`T_MOV_HDR/${this.models().T_MOV_HDR.ID}/T_MOV_ITE/${record.ID}/T_MOV_TAX`)
        .record({ ...newObj })
        .execute()
    }
  }

  getPromotions = async () => {
    const T_SET_PRM = await dbRequest
      .loadRecords("T_SET_PAR")
      .where("ID", "==", "T_REC_PRM")
      .execute(async (r) => {
        if (!r) return []
        return await evaluateFunction(r[0].VALUE)
      })

    if (!T_SET_PRM) return []
    for (const promotion of T_SET_PRM) {
      promotion.T_SET_IPR = await dbRequest
        .loadRecords(`T_SET_PRM/${promotion.ID}/T_SET_IPR`)
        .execute()

      for (const item of promotion.T_SET_IPR) {
        item.T_SET_RPR = await dbRequest
          .loadRecords(`T_SET_PRM/${promotion.ID}/T_SET_IPR/${item.ID}/T_SET_RPR`)
          .execute(r => r.sort((a, b) => Number(b.ID) - Number(a.ID)))
      }
    }
    this.T_SET_PRM = T_SET_PRM
    return T_SET_PRM
  }

  async getTAX() {
    const taxesArray = await dbRequest
      .loadRecords("T_SET_TAX")
      .execute(r => r.sort((a, b) => a?.ORDERBY - b?.ORDERBY))

    for (const tax of taxesArray) {
      tax.T_SET_TAX_ITEMS = await dbRequest.loadRecords(`T_SET_TAX/${tax.ID}/T_SET_TAX_ITEMS`).execute()
    }

    return taxesArray
  }

  async getTaxTemplate() {
    const taxes = await this.taxes

    const taxesTemplate = {}

    for (const tax of taxes) {
      taxesTemplate[tax.ID] = {}
      taxesTemplate[tax.ID].T_MOV_TAX_ITEMS = {}
      for (const field of tax.T_SET_TAX_ITEMS) {
        taxesTemplate[tax.ID].T_MOV_TAX_ITEMS[field.ID] = null
      }
    }
    return taxesTemplate
  }

  async getProductsInPromotion() {
    let values = null
    if (this.T_SET_PRM.length > 0 && this.T_SET_PRM[0].VALUE === 0) {
      values = this.T_SET_PRM[0].T_SET_IPR?.map(item => item.ID)
      return values
    } else return []
  }

  async getProductUnits(productID) {
    return await dbRequest
      .loadRecords(`T_REC_ITE/${productID}/T_REC_IUN`)
      .execute()
  }

  async getFilters() {
    return await dbRequest
      .loadRecords("T_DTD_TAB/T_MOV_ITE/T_DTD_FLD/ITEMID/T_DTD_ATR")
      .execute(r => {
        const filter = r.find((item) => item.ID === "FILTER")
        return evaluateFunction(filter?.VALUE ?? "return []")
      })
  }
}

