import Navigation from "../../../../models/navigation"
import Permissions from "../../../../models/permissions"

const onNavButtonClick = ({
  option,
  menuOptions,
  navigation,
  t,
  setMenuIsExpanded,
  setCurrentMenu,
  setNavigationPath,
  setSelectedTabName
}) => {
  if (option.SUB_MENUS && option.SUB_MENUS.length > 0) {
    setMenuIsExpanded(true)
    return navigateToSubMenu({
      subMenus: option.SUB_MENUS,
      label: t(option.DESCRIPTION),
      setCurrentMenu,
      setNavigationPath
    })
  }
  else {
    if (!option?.isLauncher) return
    Permissions.setCurrentRoutine(option.ID)

    if (option.TYPE?.VALUE === "Report") {
      return navigation.navigate("Root", {
        screen: "Report",
        params: {
          attribute: option.ID,
          controller: null,
          transaction: option.ID
        }
      })
    }

    if (option.DESCRIPTION === "Overview") navigation.navigate("Root", { screen: "Overview" })
    else Navigation.createTab({
      component: option.TYPE?.VALUE,
      params: {
        RootParentID: option.RootParentID,
        attribute: option.ID,
        transaction: option.ID,
        screenType: option.TYPE.VALUE
      },
      tabName: option.DESCRIPTION
    })

    setSelectedTabName(option.RootParentID || option.DESCRIPTION)
    setCurrentMenu(menuOptions)
    setMenuIsExpanded(false)
    setNavigationPath([])
  }
}

export default onNavButtonClick

const navigateToSubMenu = ({ subMenus, label, setCurrentMenu, setNavigationPath }) => {
  setCurrentMenu(subMenus)
  setNavigationPath(prevPath => [...prevPath, label])
}
