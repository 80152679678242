import React, { useState } from "react"
import * as S from "./styles"

export default function DateTime({ fieldId, fieldValue, changeFieldValue }) {

  const [state, setState] = useState(() => {
    if (typeof fieldValue === "string") return fieldValue
    else return ""
  })

  const onBlur = () => {

    const formatDate = str => {
      if (str === "") return null

      const [date, time] = str.split("-")
      const [day, month, year] = date.split("/")
      const formattedDate = `${year?.trim()}-${month?.trim()}-${day?.trim()}`

      return `${formattedDate} ${time ? time?.trim() : "00:00:00"}`
    }

    const value = formatDate(state)

    changeFieldValue({
      field: fieldId,
      value: value ? new globalDate(value).toISO() : null
    })
  }

  return (
    <S.Container>
      <S.Input
        type="datetime-local"
        placeholder={"DD/MM/YYYY - HH:mm:ss"}
        value={state}
        onChange={e => setState(e.target.value)}
        onBlur={onBlur}
      />
    </S.Container>
  )
}
