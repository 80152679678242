import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNotifications } from "../../../../../context/NotifcationContext.web"
import { defaultTheme } from "../../../../../styles/themes/default"
import ModalWrapper from "../../../Modal"
import NotificationItem from "./NotificationItem"
import * as S from "./styles"

const groupNotificationsByDay = (notifications) => {
  const groupedNotifications = {}

  const today = new Date().toLocaleDateString()

  notifications.forEach(notification => {
    const date = new Date(notification.CREATED_AT).toLocaleDateString()
    if (!groupedNotifications[date]) {
      groupedNotifications[date] = []
    }
    groupedNotifications[date].push(notification)
  })

  return Object.keys(groupedNotifications).map(date => ({
    title: date === today ? "Today" : date,
    data: groupedNotifications[date]
  }))
}

export default function NotificationsModal({ isVisible, setIsVisible }) {

  const { t } = useTranslation()

  const { notifications, onUpdateNotifications, handleMarkAllAsRead } = useNotifications()

  const sections = groupNotificationsByDay(notifications)

  useEffect(() => {
    onUpdateNotifications()
  }, [])

  return (
    <ModalWrapper isVisible={isVisible} onBackdropPress={() => setIsVisible(false)}>
      <S.Container>
        <S.HeaderContainer>
          <S.ModalTitle>{t("Notifications")}</S.ModalTitle>
          <S.HeaderButtons>
            <S.MarkAllAsReadButton onClick={async () => {
              await handleMarkAllAsRead()
              onUpdateNotifications()
            }}>
              <S.Icon>
                <MaterialIcons
                  name="mark-as-unread"
                  size={14}
                  style={{ marginLeft: 1 }}
                  color={defaultTheme["white"]}
                />
              </S.Icon>
              <S.TitleButton>{t("Mark all as read")}</S.TitleButton>
            </S.MarkAllAsReadButton>

            <S.CloseButton onClick={() => setIsVisible(false)}>
              <MaterialCommunityIcons
                name="window-close"
                size={26}
                color={defaultTheme["gray-700"]}
              />
            </S.CloseButton>
          </S.HeaderButtons>
        </S.HeaderContainer>
        <S.Content>
          <S.NotificationsContainer>
            {sections.length === 0 && <S.NoNotifications>{t("No notifications")}</S.NoNotifications>}
            {sections.map((section, index) => (
              <S.Section key={index}>
                <S.SectionTitle>{t(section.title)}</S.SectionTitle>
                {section.data.map((notification) => <NotificationItem key={notification.ID} notification={notification} />)}
              </S.Section>
            ))}
          </S.NotificationsContainer>
        </S.Content>
      </S.Container>
    </ModalWrapper>
  )
}
