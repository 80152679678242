import { Entypo } from "@expo/vector-icons"
import styled from "styled-components"
import styledNative from "styled-components/native"
import { defaultTheme } from "../../../../../../../styles/themes/default"

export const Container = styled.div`
  height: 31px;
  display: flex;
  align-items: center;
  gap: 8px;

  cursor: pointer;

  border-radius: 10px;
  border: 1px solid ${defaultTheme["gray-300"]};

`

export const Box = styled.div`
  background-color: ${({ isActive }) => isActive ? defaultTheme["cyan-700"] : defaultTheme["white"]};
  width: 18px;
  height: 18px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  border: 1px solid ${defaultTheme["gray-600"]};
  border-width: ${({ isActive }) => (isActive ? 0 : 1)}px;
  border-radius: 6px;

`

export const Icon = styledNative(Entypo)`
  color: ${defaultTheme["white"]};
  font-size: 12px;
`

export const Title = styled.div`
  color: ${defaultTheme["gray-700"]};
  font-size: 14px;
`
