import React, { useEffect, useState } from "react"
import * as S from "./styles.web"

export default function Currency({ fieldId, fieldValue, editable, precision, changeFieldValue, handleCalculation }) {

  const executeAction = (value) => {
    switch (fieldId) {
      case "AMOUNTNEG":
        return handleCalculation("change-unit-price", {
          value: value || 0,
          fieldId
        })

      case "DISCOUNTAMOUNT":
        return handleCalculation("change-discount-value", {
          value: value || 0,
          fieldId
        })

      default:
        return changeFieldValue({
          field: fieldId,
          value: value || 0
        })
    }
  }

  const [state, setState] = useState(fieldValue || 0)

  useEffect(() => {
    setState(fieldValue)
  }, [fieldValue])

  return (
    <S.Container editable={editable}>
      <S.Input
        value={state}
        placeholder='0,00'
        type="money"
        prefix="R$"
        editable={editable}
        isNegative={state < 0}
        precision={precision}
        onChangeValue={r => {
          setState(r || 0)
          executeAction(r)
        }}
      />
    </S.Container>
  )
}
