import { FontAwesome5, Ionicons, Feather, AntDesign } from "@expo/vector-icons"
import { useNavigation } from "@react-navigation/native"
import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNotifications } from "../../../context/NotifcationContext.web"
import Observable from "../../../models/Observable"
import RuntimeConfiguration from "../../../models/runtimeConfiguration"
import { defaultTheme } from "../../../styles/themes/default"
import BranchLogo from "../BranchLogo"
import LoadingAnimation from "../LoadingAnimation"
import MenuButton from "./components/MenuButton"
import MoreOptionsModal from "./components/MoreOptionsModal"
import NotificationsModal from "./components/NotificationsModal"
import UserArea from "./components/UserArea"
import { useMenu } from "./hooks/useMenu"
import * as S from "./styles.web"
import branchSwap from "./utils/branchSwap"
import checkScreenFocus from "./utils/checkScreenFocus"
import goBackToPreviousMenu from "./utils/goBackToPreviousMenu"
import onNavButtonClick from "./utils/onNavButtonClick"

export const menuDrawerToggleObserver = new Observable()

export default function MenuDrawer({ selectedTabName, setSelectedTabName }) {
  const { t } = useTranslation()
  const navigation = useNavigation()

  const {
    controller,
    menuOptions,
    isLoading,
    currentMenu,
    setCurrentMenu,
    showMoreOptionsModal,
    setShowMoreOptionsModal,
    branchs
  } = useMenu()
  const [menuIsExpanded, setMenuIsExpanded] = useState(false)
  const [navigationPath, setNavigationPath] = useState([])
  const [showNotificationsModal, setShowNotificationsModal] = useState(false)

  menuDrawerToggleObserver.unsubscribeAll()
  const toggle = () => setMenuIsExpanded(false)
  menuDrawerToggleObserver.subscribe(toggle)

  const { BRANCHNAME } = RuntimeConfiguration.getCurrentBranchData() as any
  const { unReadNotifications, onUpdateNotifications } = useNotifications()

  useEffect(() => {
    if (!currentMenu) setCurrentMenu(menuOptions)
  }, [menuOptions, currentMenu, setCurrentMenu, selectedTabName])

  const isScreenFocused = ({ option }) =>
    checkScreenFocus({
      option,
      selectedTabName
    })

  const handleMenuClick = (option) =>
    onNavButtonClick({
      option,
      menuOptions,
      navigation,
      t,
      setMenuIsExpanded,
      setCurrentMenu,
      setNavigationPath,
      setSelectedTabName
    })

  const renderMenuButtons = (buttons) => {
    return buttons.map((option) => (
      <MenuButton
        key={option.ID}
        option={option}
        menuIsExpanded={menuIsExpanded}
        handleMenuClick={handleMenuClick}
        isScreenFocused={isScreenFocused}
      />
    ))
  }

  const resetMenuState = () => {
    setMenuIsExpanded(false)
    setCurrentMenu(menuOptions)
    setNavigationPath([])
  }

  return (
    <S.Container menuIsExpanded={menuIsExpanded}>
      <S.Background menuIsExpanded={menuIsExpanded} onClick={resetMenuState} />

      <S.HeaderWrapper>
        <BranchLogo />
        <S.UserNameText menuIsExpanded={menuIsExpanded}>
          {BRANCHNAME}
        </S.UserNameText>
        {Object.keys(branchs).length > 1 && (
          <S.BranchSwapButton
            title={t("Branch Swap")}
            menuIsExpanded={menuIsExpanded}
            onClick={() =>
              branchSwap({
                t,
                branchs,
                closeMenu: () => setMenuIsExpanded((s) => !s),
                onUpdateNotifications
              })
            }
          >
            <AntDesign name="swap" size={18} color={defaultTheme["white"]} />
          </S.BranchSwapButton>
        )}
      </S.HeaderWrapper>

      {navigationPath.length > 0 ? (
        <S.ButtonWrapper>
          <S.MenuButton
            onClick={() =>
              goBackToPreviousMenu({
                menuOptions,
                setCurrentMenu,
                setNavigationPath,
                navigationPath,
                t
              })
            }
          >
            <div style={{ width: 50 }}>
              <Ionicons
                name="caret-back-circle-outline"
                size={22}
                color={defaultTheme["gray-500"]}
              />
            </div>
          </S.MenuButton>
          <S.MenuText
            menuIsExpanded={menuIsExpanded}
            style={{ fontWeight: "normal" }}
          >
            {navigationPath.join("/") + "/"}
          </S.MenuText>
        </S.ButtonWrapper>
      ) : (
        <S.ButtonWrapper onClick={() => setMenuIsExpanded((s) => !s)}>
          <S.MenuButton title={menuIsExpanded ? t("Hide Menu") : t("Show Menu")}>
            <div style={{ width: 50 }}>
              <Feather name="menu" size={20} color={defaultTheme["gray-700"]} />
            </div>
          </S.MenuButton>
          <S.MenuText
            menuIsExpanded={menuIsExpanded}
            style={{ fontWeight: "lighter" }}
          >
            {t("Hide Menu")}
          </S.MenuText>
        </S.ButtonWrapper>
      )}
      <S.NavigationMenuContainer>
        <S.NavigationMenu>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <LoadingAnimation color={defaultTheme["cyan-300"]} size="medium" />
            </div>
          ) : (
            renderMenuButtons(currentMenu)
          )}
        </S.NavigationMenu>
      </S.NavigationMenuContainer>
      <S.SeparatorLine />

      <S.FooterWrapper>
        <S.ButtonWrapper onClick={() => setShowNotificationsModal(true)}>
          <S.MenuButton title={t("Notifications")}>
            {unReadNotifications.length > 0 && <S.UnreadBadge>{unReadNotifications.length > 99 ? "99+" : unReadNotifications.length}</S.UnreadBadge>}
            <div style={{ width: 50 }}>
              <FontAwesome5
                name="bell"
                size={20}
                color={defaultTheme["gray-700"]}
              />
            </div>
          </S.MenuButton>
          <S.ButtonText menuIsExpanded={menuIsExpanded}>
            {t("Notifications")}
          </S.ButtonText>
        </S.ButtonWrapper>

        <S.ButtonWrapper
          onClick={() => {
            menuIsExpanded && setMenuIsExpanded((s) => !s)
            setShowMoreOptionsModal(true)
          }}

        >
          <S.MenuButton title={t("More Options")}>
            <div style={{ width: 50 }}>
              <FontAwesome5
                name="ellipsis-h"
                size={20}
                color={defaultTheme["gray-700"]}
              />
            </div>
          </S.MenuButton>
          <S.ButtonText menuIsExpanded={menuIsExpanded}>
            {t("More Options")}
          </S.ButtonText>
        </S.ButtonWrapper>

        <UserArea menuIsExpanded={menuIsExpanded} />

        <S.ButtonWrapper onClick={() => controller.handleLogout()}>
          <S.MenuButton title={t("Exit")}>
            <div style={{ width: 50 }}>
              <Feather
                name="power"
                size={22}
                color={defaultTheme["gray-700"]}
              />
            </div>
          </S.MenuButton>
          <S.ButtonText menuIsExpanded={menuIsExpanded}>
            {t("Exit")}
          </S.ButtonText>
        </S.ButtonWrapper>
      </S.FooterWrapper>

      <MoreOptionsModal
        isVisible={showMoreOptionsModal}
        setIsVisible={setShowMoreOptionsModal}
      />

      <NotificationsModal isVisible={showNotificationsModal} setIsVisible={setShowNotificationsModal} />
    </S.Container>
  )
}
