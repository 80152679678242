const createSubmenus = ({ menuData, currentDepth, maxDepth, parentCode }) => {
  if (currentDepth > maxDepth) return []
  const formattedMenu = []

  const currentLayer = menuData.filter(({ DEPTH, MASTERID }) => {
    const doesButtonBelongToCurrentLayer = DEPTH === currentDepth
    const isChildOfCurrentParent = String(MASTERID) === String(parentCode)

    return doesButtonBelongToCurrentLayer && isChildOfCurrentParent
  })

  for (const item of currentLayer) {
    item.SUB_MENUS = createSubmenus({
      menuData,
      currentDepth: currentDepth + 1,
      maxDepth,
      parentCode: item.ID
    })
    formattedMenu.push(item)
  }

  return formattedMenu
}

export default createSubmenus
