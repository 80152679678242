import { Platform } from "react-native"
import LoginData from "../../../models/loginData"
import dbRequest from "../../dbRequest/dbRequest"

const fetchNavigations = async () => {
  const customMenu = await dbRequest
    .loadRecords(`T_SET_RLU/${LoginData.getLoginData().ID}/CUSTOM_SETTINGS`)
    .where("ID", "==", "T_DTD_NEW_NAV")
    .execute() as any

  const navigations = await dbRequest
    .loadRecords("T_DTD_NAV_NEW")
    .execute()

  if (Array.isArray(customMenu) && Platform.OS !== "web") {
    const orderMap = customMenu[0].CUSTOM_MENU.reduce((acc, item) => {
      acc[item.ID] = item.ORDER
      return acc
    }, {})

    const sortedNavigations = navigations.sort((a, b) => {
      const orderA = orderMap[a.ID] !== undefined ? orderMap[a.ID] : Infinity
      const orderB = orderMap[b.ID] !== undefined ? orderMap[b.ID] : Infinity
      return orderA - orderB
    })

    return sortedNavigations
  }

  return navigations.map(item => {
    if (item.ISLAUNCHER || item.isLauncher) {
      delete item.ISLAUNCHER
      return {
        ...item,
        isLauncher: true
      }
    } else return item
  })
}

export default fetchNavigations
