import { Feather } from "@expo/vector-icons"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { ActivityIndicator } from "react-native"
import getTableFields from "../../../../../../../services/registration/getTableFields"
import { defaultTheme } from "../../../../../../../styles/themes/default"
import CloseButton from "../../../../../CloseButton/index.web"
import Grid from "../../../../../Grid/index.web"
import ModalWrapper from "../../../../../Modal"
import * as S from "./styles"

export default function Search({ fieldId, fieldAttributes, models, entityFields, changeFieldValue }) {

  const { t } = useTranslation()
  const entityName = fieldAttributes.ENTITY.ID
  const gridRef = useRef(null)

  const tableData = models._REGISTRATION_DATA[entityName]
  const modalTitle = fieldAttributes.ENTITY.ENTITY || tableData?.description

  const [selectedItem, setSelectedItem] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [tableFields, setTableFields] = useState(entityFields)
  const [isLoading, setIsLoading] = useState(false)

  function populateFields(record) {
    setSelectedItem(record)
    changeFieldValue({
      field: fieldId,
      value: {
        ID: record.ID,
        DESCRIPTION: record.DESCRIPTION ?? "",
        IMG: record.IMG ?? []
      }
    })
  }

  const openModal = async () => {
    if (tableFields.length > 0) {
      setIsLoading(false)
      setModalVisible(true)
    } else {
      setIsLoading(true)
      const tableFields = await getTableFields({ entityName })
      models._REGISTRATION_DATA[entityName].fields = tableFields
      setTableFields(tableFields)
      setIsLoading(false)
      setModalVisible(true)
    }
  }

  return (
    <S.Container onClick={async () => await openModal()}>
      {isLoading ? <ActivityIndicator color={defaultTheme["cyan-700"]} size={"small"} /> :
        <S.Value
          style={{ color: selectedItem?.DESCRIPTION ? defaultTheme["gray-700"] : defaultTheme["gray-500"] }}
        >
          {selectedItem?.DESCRIPTION ?? "Pesquisar"}
        </S.Value>
      }

      <Feather name='search' color={defaultTheme["cyan-700"]} size={16} />
      {modalVisible &&
        <ModalWrapper
          isVisible={modalVisible}
          onBackdropPress={() => setModalVisible(false)}
        >
          <S.ModalContainer>
            <CloseButton callbackFunction={() => setModalVisible(false)} />

            <S.Title>{t(modalTitle)}</S.Title>

            <Grid
              entity={entityName}
              models={models}
              gridRef={gridRef}
              screenType="searchModal"
              rowDoubleClickCallback={record => {
                populateFields(record)
                setModalVisible(false)
              }}
              staticFilters={fieldAttributes.FILTER}
            />
          </S.ModalContainer>
        </ModalWrapper>
      }
    </S.Container>
  )
}
