/* eslint-disable no-case-declarations */
import {
  AggregateColumnDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  RowDataBoundEventArgs
} from "@syncfusion/ej2-react-grids"
import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { ActivityIndicator } from "react-native"
import Observable from "../../../models/Observable"
import { Field } from "../../../services/registration/getTableFields"
import showAlert from "../../../services/showAlert"
import { defaultTheme } from "../../../styles/themes/default"
import Kanban from "../Kanban/index.web"
import GridToolbar from "../Toolbars/Grid"
import RenderColumn from "./components/Column"
import useGrid from "./hooks/useGrid"
import * as S from "./styles"
import getAggregates from "./utils/getAggregates"
import getFormattedRecords from "./utils/getFormattedRecords"
import handleRowPress from "./utils/handleRowPress"
import services from "./utils/services"

export const gridObserver = new Observable()

export interface GridProps {
  entity: string;
  gridRef?: React.RefObject<GridComponent> | null;
  functions?: {
    handleInsert?: () => void;
    handleCopy?: () => void;
    handleDelete?: ({ changeLoadingModalVisibility }: { changeLoadingModalVisibility: () => void }) => void;
    handleRefresh?: () => void;
    changeView?: () => void
  };
  models?: any;
  rowDoubleClickCallback?: (record?: object) => void;
  detailTabPath?: string;
  screenType?: "registrationMaster" | "registrationDetail" | "orderPanel" | "searchModal";
  updateGrid?: boolean;
  staticFilters?: string;
  setLoadingFormConfiguration?: (loading: boolean) => void;
  toggleMode?: () => void;
  mode: "grid" | "form";
}

export default function Grid({
  entity,
  gridRef,
  functions,
  models,
  rowDoubleClickCallback,
  detailTabPath = null,
  screenType,
  updateGrid,
  staticFilters,
  setLoadingFormConfiguration = () => null,
  toggleMode = () => null,
  mode
}: GridProps) {

  const { t } = useTranslation()

  const {
    fieldsData,
    records,
    updateGridRecords,
    loading,
    isKanban,
    setIsKanban
  } = useGrid({
    entity,
    models,
    detailTabPath,
    updateGrid,
    gridRef,
    screenType,
    staticFilters
  })

  const { fields, detailTabs } = fieldsData
  const aggregates = getAggregates(fields)

  const checkTableDataFields = (callback) => {
    if (screenType === "searchModal") return
    setLoadingFormConfiguration(true)

    const interval = setInterval(() => {
      const entityFields = models._REGISTRATION_DATA[entity].fields

      if (entityFields && entityFields.length > 0) {
        clearInterval(interval)
        setLoadingFormConfiguration(false)
        callback()
      }
    }, 100)
  }

  if (screenType !== "searchModal") {
    functions.changeView = () => {
      checkTableDataFields(() => {
        const isKanbanSupported = models._REGISTRATION_DATA[entity].fields.some(
          (field) => field.T_DTD_ATR?.ISKANBAN
        )

        if (isKanbanSupported) {
          setIsKanban(s => !s) // Alterna entre Kanban e a outra visualização
        } else {
          showAlert({
            titleType: "warning",
            title: "Alert",
            text: "This screen is not supported in Kanban view"
          })
        }
      })
    }

  }

  const editOptions = {
    allowAdding: true,
    allowDeleting: true
  }

  const selectionSettings = {
    type: "Single",
    checkboxMode: "ResetOnRowClick"
  }

  gridObserver.unsubscribeAll()
  const handleRefresh = () => updateGridRecords()
  gridObserver.subscribe(handleRefresh)

  const renderAggregate = useCallback(
    ({ operation, field }) => {

      const aggregateTemplate = props => (
        <S.FooterItem>
          <S.BoldText>{t(operation)}:</S.BoldText>
          <span>{props.Custom}</span>
        </S.FooterItem>
      )

      const calculateAggregate = (op, args) => {

        let currentData = []

        if (args && args.items) {
          currentData = args.items

        } else {
          currentData = gridRef && gridRef.current && gridRef.current.getFilteredRecords &&
            gridRef.current.getFilteredRecords().length > 0
            ? gridRef.current.getFilteredRecords()
            : models._REGISTRATION_DATA[entity].records
        }

        let value = 0

        if (op === "sum") {
          value = currentData.reduce((accumulator, obj) => {
            let fieldValue = obj[field.ID]

            if (
              field.FIELDTYPE.VALUE === "H" &&
              typeof fieldValue === "string" &&
              fieldValue.includes(":")
            ) {
              const parts = fieldValue.split(":")
              const h = Number(parts[0])
              const m = Number(parts[1])
              fieldValue = h * 60 + m
            }
            if (typeof fieldValue === "number" || !isNaN(fieldValue)) {
              return accumulator + Number(fieldValue)
            } else {
              return accumulator
            }
          }, 0)
        } else if (op === "average") {
          const validValues = currentData
            .map(obj => {
              let fieldValue = obj[field.ID]
              if (
                field.FIELDTYPE.VALUE === "H" &&
                typeof fieldValue === "string" &&
                fieldValue.includes(":")
              ) {
                const parts = fieldValue.split(":")
                const h = Number(parts[0])
                const m = Number(parts[1])
                fieldValue = h * 60 + m
              }
              return (typeof fieldValue === "number" || !isNaN(fieldValue))
                ? Number(fieldValue)
                : null
            })
            .filter(val => val !== null)
          if (validValues.length > 0) {
            value = validValues.reduce((acc, v) => acc + v, 0) / validValues.length
          } else {
            value = 0
          }
        }

        if (field.FIELDTYPE.VALUE === "H") {
          const hours = Math.floor(value / 60)
          const remainingMinutes = value % 60
          const formattedHours = new Intl.NumberFormat("pt-BR").format(hours)
          return `${formattedHours}:${remainingMinutes.toString().padStart(2, "0")}`
        }

        if (field.FIELDTYPE.VALUE === "C") {
          const formattedValue = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2
          }).format(value)
          return formattedValue
        }

        if (field.FIELDTYPE.VALUE === "I") {
          const formattedValue = new Intl.NumberFormat("pt-BR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }).format(value)
          return formattedValue
        }

        return value.toFixed(field.T_DTD_ATR.DECIMAL ?? 2)
      }

      const op = operation.toLowerCase()

      return (
        <AggregateColumnDirective
          key={field.ID}
          field={field.ID}
          type={"Custom"}
          customAggregate={(args) => calculateAggregate(op, args)}
          footerTemplate={aggregateTemplate}
          groupFooterTemplate={aggregateTemplate}
        />
      )
    },
    [t, models, entity, gridRef]
  )

  const toolbarTemplate = useCallback(() => (
    <GridToolbar
      gridRef={gridRef}
      entity={entity}
      models={models}
      screenType={screenType}
      functions={{
        ...functions,
        handleRefresh: async () => {
          gridRef.current.showSpinner()
          await updateGridRecords()
          gridRef.current.hideSpinner()
        }
      }}
    />
  ), [fields])

  const formattedRecords = getFormattedRecords({
    records,
    models,
    entity
  })

  /* if (gridRef.current !== null && models._REGISTRATION_DATA[entity].savedProperties)
    applySettings({
      settings: models._REGISTRATION_DATA[entity].savedProperties,
      fields,
      gridRef
    }) */

  const onRowPress = (args: RowDataBoundEventArgs) => {
    args.row.style.cursor = screenType === "searchModal" ? "pointer" : "default"
    handleRowPress({
      args,
      grid: gridRef.current,
      entity,
      models,
      detailTabs,
      records,
      rowDoubleClickCallback,
      screenType
    })
  }

  const pageSettings = {
    pageSize: screenType === "searchModal" ? 100 : 50,
    pageCount: 4,
    pageSizes: [30, 50, 100, 200, "All"]
  }

  return (
    <>
      <div style={{ display: (!isKanban || loading) ? "none" : "block" }}>
        <Kanban
          setIsKanban={setIsKanban}
          screenType={screenType}
          fields={fields}
          records={formattedRecords}
          models={models}
          entity={entity}
          loading={loading}
          gridRef={gridRef}
          detailTabs={detailTabs}
          toggleMode={toggleMode}
          mode={mode}
          updateGridRecords={updateGridRecords}
        />

      </div>

      <S.Container style={{ display: isKanban ? "none" : "block" }}>
        <style>
          {`
          div.e-gridcontent {
            height: calc(100% - ${screenType === "searchModal"
              ? 65 + aggregates.length * 27
              : 164 + aggregates.length * 27
            }px) !important;
          }
        `}
        </style>
        <GridComponent
          id={"Grid" + entity}
          key={"Grid" + entity}
          allowExcelExport={true}
          allowFiltering={true}
          allowGrouping={screenType !== "searchModal" ?? false}
          allowMultiSorting={true}
          allowPaging={true}
          allowReordering={true}
          allowResizing={true}
          allowSelection={true}
          allowSorting={true}
          dataSource={loading ? [] : formattedRecords}
          editSettings={editOptions}
          enableHover={true}
          locale="pt"
          pageSettings={pageSettings}
          filterSettings={{
            type: "Excel",
            showFilterBarStatus: true
          }}
          emptyRecordTemplate={() => {
            if (!loading && !formattedRecords.length)
              return (
                <div
                  style={{
                    position: "absolute",
                    top: 20,
                    left: "50%",
                    width: "100%",
                    height: "100%"
                  }}
                >
                  <span style={{ color: defaultTheme["gray-500"] }}>
                    {t("No records to display")}
                  </span>
                </div>
              )
            if (loading)
              return (
                <div
                  style={{
                    position: "absolute",
                    top: 20,
                    left: 0,
                    width: "100%",
                    height: "100%"
                  }}
                >
                  <ActivityIndicator
                    size={"small"}
                    color={defaultTheme["cyan-800"]}
                  />
                </div>
              )
          }}
          gridLines="Both"
          height="100%"
          ref={gridRef}
          rowDataBound={onRowPress}
          selectionSettings={selectionSettings}
          showColumnChooser={true}
          toolbar={[""]}
          toolbarTemplate={toolbarTemplate}
          width={"100%"}
        >
          <ColumnsDirective>
            <ColumnDirective
              type="checkbox"
              width={80}
              textAlign="Center"
              headerTextAlign="Center"
              visible={screenType !== "searchModal" ?? true}
            />
            {fields
              .filter(({ ID, FIELDTYPE }) =>
                FIELDTYPE.VALUE === "A" ? ID === "IMG" : true
              )
              .map((field) =>
                RenderColumn({
                  fieldData: field,
                  t
                })
              )}
          </ColumnsDirective>

          <AggregatesDirective>
            {aggregates.map(({ operation, fields }) => (
              <AggregateDirective key={operation}>
                <AggregateColumnsDirective>
                  {fields.map((field: Field) =>
                    renderAggregate({
                      operation,
                      field
                    })
                  )}
                </AggregateColumnsDirective>
              </AggregateDirective>
            ))}
          </AggregatesDirective>

          <Inject services={services} />
        </GridComponent>

      </S.Container>
    </>
  )
}
