import showAlert from "../../../../../../../../services/showAlert"

const handleInsert = ({ models, entity, mode, setModalVisible, setUpdateForm, setLoadingFormConfiguration = (s) => s }) => {

  const tableData = models._REGISTRATION_DATA[entity]

  const clearModels = () => {
    models[entity].reset()
    tableData.operation = "INS"
    tableData.files = {}
    tableData.currentRecordIndex = null
  }

  const checkTableDataFields = (callback) => {
    setLoadingFormConfiguration(true)

    const interval = setInterval(() => {
      if (tableData.fields && tableData.fields.length > 0) {
        clearInterval(interval)
        setLoadingFormConfiguration(false)
        callback()
      }
    }, 100) // Verifica a cada 100ms se tableData.fields está populado
  }

  const handleInsertProcess = () => {
    if (mode === "form") {
      showAlert({
        text: "Do you want to insert a new record?",
        type: "confirm",
        titleType: "question",
        title: "Unsaved changes will be lost",
        handleConfirm: () => {
          console.log("alo")

          clearModels()
          setUpdateForm(s => !s)
          setLoadingFormConfiguration(false)
        }
      })
    } else {
      clearModels()
      setModalVisible(true)
      setLoadingFormConfiguration(false)
    }
  }

  checkTableDataFields(handleInsertProcess) // Verifica se tableData.fields foi populado antes de prosseguir
}

export default handleInsert
