import dbRequest from "../../../../services/dbRequest/dbRequest"
import runTriggers from "../../../../services/registration/runTriggers"
import showAlert from "../../../../services/showAlert"
import { gridObserver } from "../../Grid"

const handleSave = async ({
  eventData,
  entityName,
  models,
  kanbanField,
  options,
  setUpdate
}) => {
  try {
    const { ID } = eventData
    const tableData = models._REGISTRATION_DATA[entityName]

    const labelValue = eventData[kanbanField]

    tableData.operation = "DEL"
    tableData.state = "BEFORE"

    const unformattedRecord = models._REGISTRATION_DATA[
      entityName
    ].records.find((record) => record.ID === ID)

    Object.keys(options).forEach((key) => {
      if (options[key].LABEL === labelValue) {
        unformattedRecord[kanbanField] = {
          VALUE: key,
          LABEL: options[key].LABEL
        }
      }
    })

    const allTriggersValid = await runTriggers(models, entityName)
    if (!allTriggersValid) {
      setUpdate()
      return false
    } else {
      await dbRequest.saveRecords(entityName).record(unformattedRecord)
        .execute()

      tableData.operation = "UPD"
      tableData.state = "AFTER"

      await runTriggers(models, entityName)
    }

    gridObserver.notify()
    showAlert({
      text: "Changes saved successfully",
      titleType: "success"
    })
  } catch (error) {
    showAlert({
      text: "Error saving changes",
      titleType: "error"
    })
  }
}

export default handleSave
