/* eslint-disable no-async-promise-executor */
import globalDate from "../global/globalDate"
import LoginData from "../models/loginData"
import PriceTableData from "../models/priceTableData"
import dbRequest from "./dbRequest/dbRequest"
import getItemById from "./getItemById"
import QueryBuilder from "./QueryBuilder"
import sendNotification from "./sendNotification"
import showAlert from "./showAlert"

export default async function evaluateFunction(_functionString, models, params) {

  const functionStructure = `return (() => {${_functionString}})();`

  return new Promise(async (resolve, reject) => {
    try {
      const dynamicFunction = new Function(
        "dbRequest",
        "QueryBuilder",
        "LoginData",
        "PriceTableData",
        "globalDate",
        "showAlert",
        "models",
        "params",
        "getItemById",
        "sendNotification",
        functionStructure
      )
      const result = await dynamicFunction(dbRequest, QueryBuilder, LoginData, PriceTableData, globalDate, showAlert, models, params, getItemById, sendNotification)
      resolve(result)
    } catch (error) {
      reject(error)
    }
  })
}
