import React from "react"
import { View } from "react-native"
import { defaultTheme } from "../../../styles/themes/default"
import LoadingAnimation from "../LoadingAnimation"
import RenderTabs from "./components/RenderTabs"
import useForm from "./hooks/useForm"
import * as S from "./styles.web"

export default function Form({
  entity,
  hideTabs = false,
  models,
  screenBuilder = false,
  setUpdateForm = () => null,
  staticFields = null,
  updateForm = false
}) {

  const { fields, tabs, loading } = useForm({
    entity,
    models,
    screenBuilder,
    staticFields,
    updateForm
  })

  const hasTabs = tabs.length > 0

  return (
    <S.Container>
      {hasTabs && !loading ? (
        <RenderTabs
          fields={fields}
          hideTabs={hideTabs}
          models={models}
          setUpdateForm={setUpdateForm}
          tabs={tabs}
          updateForm={updateForm}
          screenBuilder={screenBuilder}
        />
      ) :
        (
          <View style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center"
          }}>
            <LoadingAnimation size="large" color={defaultTheme["cyan-700"]} />
          </View>
        )}
    </S.Container>
  )
}

