import React, { useEffect, useState } from "react"
import showAlert from "../../../../../services/showAlert"
import { defaultTheme } from "../../../../../styles/themes/default"
import DynamicModal from "./components/DynamicModal/index"
import ModalContent from "./components/ModalContent"
import * as S from "./styles"
import getOptions from "./utils/getOptions"
import handleExport from "./utils/handleExport"

export default function MoreOptionsButton({ entity, models, gridRef, setUpdateForm, mode = "grid", disabled = false }) {

  const [options, setOptions] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleSelect = ({ item }) => {

    if (item.EXPRESSION === "fnc_exportSpreadsheet") {
      return handleExport({
        entity,
        gridRef
      })
    }

    if (item.SELECTLINE) {
      if (gridRef?.current && gridRef.current?.getSelectedRecords().length > 1) {
        return showAlert({
          text: "Multiple records must selected",
          title: "Failed to execute function",
          titleType: "warning"
        })
      }

      const selectedRecords = models[entity]

      if (Object.keys(selectedRecords).length === 0)
        return showAlert({
          text: "A record must be selected",
          title: "Failed to execute function",
          titleType: "warning"
        })

      const record = models._REGISTRATION_DATA[entity].records.find(({ ID }) => ID == selectedRecords.ID)

      if (record?.TYPE?.VALUE !== "T_MOV_HDR" && item.DESCRIPTION === "Configure items") {
        return showAlert({
          text: "It is not possible to configure 'Items' for this transaction.",
          title: "Action not permitted",
          titleType: "warning"
        })
      }

      models[entity].populate({ ...record })
    }

    if (item.EXPRESSION === "fnc_access" && models[entity].ADMIN)
      return showAlert({
        text: "It is not possible to change an admin's access",
        title: "Access denied",
        titleType: "warning"
      })

    setSelectedOption(item)
    setIsOpen(false)
    setModalVisible(true)
  }

  useEffect(() => {
    getOptions({
      entity,
      models,
      mode
    }).then(setOptions)
  }, [])

  const isDisabled = options.length === 0 || disabled

  const toggleDropdown = () => {
    if (!isDisabled) setIsOpen(!isOpen)
  }

  return (
    <div style={{ position: "relative" }}>

      <S.Container
        onClick={toggleDropdown}
        style={{
          border: isOpen ? `2px solid ${defaultTheme["cyan-300"]}` : "none",
          backgroundColor: isDisabled ? defaultTheme["gray-100"] : defaultTheme["white"],
          cursor: isDisabled ? "not-allowed" : "pointer"
        }}
      >
        <S.IconContainer style={{ backgroundColor: isDisabled ? defaultTheme["gray-400"] : defaultTheme["cyan-300"] }}>
          <S.Icon name="ellipsis-h" />
        </S.IconContainer>
      </S.Container>

      {isOpen && (
        <S.Dropdown>
          {options.map((item, index) => (
            <S.DropdownItem
              key={index}
              onClick={() => handleSelect({ item })}
            >
              {item.DESCRIPTION}
            </S.DropdownItem>
          ))}
        </S.Dropdown>
      )}

      {modalVisible && (
        <DynamicModal isVisible={modalVisible}>
          <ModalContent
            entity={entity}
            models={models}
            closeModal={() => setModalVisible(false)}
            selectedOption={selectedOption}
            gridRef={gridRef}
            setUpdateForm={setUpdateForm}
          />
        </DynamicModal>
      )}
    </div>
  )
}

