import { MaterialIcons } from "@expo/vector-icons"
import Entypo from "@expo/vector-icons/Entypo"
import React, { useEffect, useState } from "react"
import Chart from "react-google-charts"
import { useTranslation } from "react-i18next"
import { View } from "react-native"
import { useDispatch } from "react-redux"
import useAppSelector from "../../../../../redux/hooks/useAppSelector"
import { getModalData } from "../../../../../redux/redux-reducers/dashboard-reducer"
import { defaultTheme } from "../../../../../styles/themes/default"
import ModalWrapper from "../../../../../views/components/Modal"
import SliderInput from "../../SliderInput"
import PivotChart from "../PivotChart"
import * as S from "../styles.web"
import useSetChart from "./hooks/useSetChart"

export default function ModalChart({ dashboardData }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { data, attributes, chartType, isVisible, chartData } = useAppSelector(
    ({ dashboardReducer }) => dashboardReducer.modal
  )

  const { actualValue, setActualValue } = useSetChart(data, isVisible)

  const [isPivotChart, setIsPivotChart] = useState(false)
  const [isToggling, setIsToggling] = useState(false)

  const dataId = chartData?.DATAID

  useEffect(() => {
    if (!isVisible) {
      setIsPivotChart(false)
    }
  }, [isVisible])

  const handleToggle = () => {
    if (isToggling) return

    setIsToggling(true)
    setIsPivotChart((prev) => !prev)

    setTimeout(() => {
      setIsToggling(false)
    }, 300)
  }

  return (
    <ModalWrapper isVisible={isVisible}>
      <View
        style={{
          height: "100%",
          width: "100%",
          paddingBottom: 10,
          borderRadius: 10,
          backgroundColor: defaultTheme["gray-200"]
        }}
      >
        <View
          style={{
            width: "100%",
            height: "auto",
            padding: 10,
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 1
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            zIndex: 1
          }}
        >
          <S.Header>
            <S.ButtonsView isVisible={isVisible}>
              {!["PieChart", "Table"].includes(chartType) && !isPivotChart && (
                <SliderInput
                  value={actualValue}
                  minValue={1}
                  maxValue={!data ? 1 : data.length}
                  onChange={setActualValue}
                />
              )}

              <S.CloseButton
                onClick={() => dispatch(getModalData({ isVisible: false }))}
              >
                <MaterialIcons name="close" size={20} color="#333333" />
              </S.CloseButton>
            </S.ButtonsView>

            <S.Title>{attributes?.title}</S.Title>
          </S.Header>

          <S.ContainerButton onClick={handleToggle}>
            <S.PivotButton>
              {isPivotChart ? (
                <Entypo name="line-graph" size={16} color={defaultTheme["white"]} />
              ) : (
                <MaterialIcons name="description" size={16} color={defaultTheme["white"]} />
              )}
            </S.PivotButton>
            <S.ButtonText>
              {isPivotChart ? t("Chart") : t("Pivot")}
            </S.ButtonText>
          </S.ContainerButton>
        </View>

        {isPivotChart ? (
          <PivotChart dashboardData={dashboardData} dataId={dataId} chartData={chartData} />
        ) : (
          <Chart
            data={data}
            chartType={chartType}
            height="100%"
            chartEvents={[
              {
                eventName: "error",
                callback: ({ google, chartWrapper }) =>
                  google.visualization.errors.removeAll(
                    document.getElementById(chartWrapper.getContainerId())
                  )
              }
            ]}
            options={{
              width: "100%",
              pageSize: 200,
              ...attributes,
              hAxis: {
                viewWindow: {
                  min: 0,
                  max: chartType === "BarChart" ? undefined : actualValue
                },
                slantedText: true
              },
              vAxis: {
                viewWindow: {
                  min: 0,
                  max: chartType === "BarChart" ? actualValue : undefined
                }
              }
            }}
          />
        )}
      </View>
    </ModalWrapper>
  )
}
