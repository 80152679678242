import styled from "styled-components"
import styledNative from "styled-components/native"
import { defaultTheme } from "../../../../../../../styles/themes/default"
import { InputField } from "../../../../../defaultInputsStyles"

export const Container = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid ${defaultTheme["gray-300"]};
`

export const Input = styledNative(InputField)
  .attrs(({ editable }) => ({
    editable: editable,
    focusable: editable,
    keyboardType: "numeric",
    placeholderTextColor: defaultTheme["gray-300"],
    prefix: "R$ "
  }))`
    width: 100%;
    background-color: ${({ editable }) => defaultTheme[editable ? "white" : "gray-200"]};
    color: ${({ isNegative }) => defaultTheme[isNegative ? "red-500" : "gray-700"]};
    font-size: 12px;

    padding: 0 10px;
    height: 100%;
    outline: none;
`

