import styled from "styled-components"
import { defaultTheme } from "../../../styles/themes/default"

interface MenuProps {
  menuIsExpanded: boolean
  isSelected?: boolean
}

interface BackgroundProps {
  menuIsExpanded: boolean;
}

export const Container = styled.div<MenuProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: ${({ menuIsExpanded }) => menuIsExpanded ? 260 : 63}px;
  position: fixed;
  box-shadow: 4px 0 5px #00000029;
  background-color: ${({ theme }) => theme["white"]};
  transition: width .3s ease-in-out;
  z-index: 999;
`

export const Background = styled.div<BackgroundProps>`
  width: calc(100vw - 260px);
  visibility: ${({ menuIsExpanded }) => (menuIsExpanded ? "visible" : "hidden")};
  height: 100vh;
  background-color: ${defaultTheme["black"]};
  opacity: ${({ menuIsExpanded }) => (menuIsExpanded ? 0.3 : 0)};
  position: absolute;
  margin-left: 260px;
  cursor: ${({ menuIsExpanded }) => (menuIsExpanded ? "pointer" : "default")};
  transition: opacity ${({ menuIsExpanded }) => (menuIsExpanded ? "1.2s" : "0s")} ease-in-out;
`

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.625rem;
  padding-top: 0.875rem; // 14px
  padding-left: 0.375rem; // 6px
  margin-bottom: 1.25rem; // 20px
  overflow: hidden;
  white-space: nowrap;
`

export const UserNameText = styled.h2<MenuProps>`
  color: ${({ theme }) => theme["cyan-700"]};
  font-size: 1.125rem; // 18px
  opacity: ${({ menuIsExpanded }) => menuIsExpanded ? 1 : 0};
  transition: opacity .2s ease-in-out;
`

export const ButtonWrapper = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 0.375rem; // 6px
  padding-right: 1.875rem; // 30px
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: transparent;
  opacity: 1;
  transition: opacity .16s ease-in-out;

  &:active {
    opacity: 0.3;
  }
`

export const MenuButton = styled.div`
  display: flex;
  align-items: center;
  width: 3.125rem; // 50px
  height: 14%;
`

export const UnreadBadge = styled.div`
  position: absolute;
  top: 0; 
  right: 20px; 
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: ${defaultTheme["white"]};
  text-align: center;
  font-size: 8px;
  font-weight: 600;
  background-color: ${defaultTheme["red-500"]};
  z-index: 100;
`

export const MenuText = styled.p<MenuProps>`
  text-align: center;
  color: ${({ theme, isSelected }) => isSelected ? theme["cyan-300"] : theme["gray-700"]};
  font-size: 0.83rem;
  letter-spacing: 0.002rem;
  margin-left: 0.625rem; // 10px
  white-space: nowrap;
  transition: opacity .3s ease-in-out;
  opacity: ${({ menuIsExpanded }) => menuIsExpanded ? 1 : 0};
  cursor: pointer;
  &:active {
    opacity: 0.3;
  }

  ${({ menuIsExpanded }) => menuIsExpanded && `
    animation: changeWhiteSpace 2s forwards;
  `}

  @keyframes changeWhiteSpace {
    0% {
      white-space: nowrap;
    }
    100% {
      white-space: normal;
    }
  }
`

export const NavigationMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  scroll-behavior: smooth;
  margin-top: 0.9375rem; // 15px

   /* Esconde scrollbar para Chrome e Safari */
   &::-webkit-scrollbar {
    display: none;
  }

  /* Esconde scrollbar para Firefox */
  scrollbar-width: none;

  /* Esconde scrollbar para Internet Explorer e Edge */
  -ms-overflow-style: none;
`

export const NavigationMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem; // 10px
  width: 100%;
`

export const SeparatorLine = styled.div`
  width: 70%;
  height: 0.0625rem; // 1px
  border-radius: 0.0625rem; // 1px
  background-color: ${({ theme }) => theme["gray-400"]};
  align-self: center;
  margin: 0.5rem 18%; // 8px
`

export const FooterWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  padding: 0.5rem 0 0.875rem 0; // 8px e 14px
  margin-bottom: 0.625rem; // 10px

  @media (max-width: 1024px) {
    gap: 0.375rem;
    padding: 0.375rem 0 0.75rem 0; // 6px e 12px
  }

  @media (max-width: 1440px) {
    gap: 0.625rem;
    padding: 0.625rem 0 1rem 0; // 10px e 16px
  }
`

export const OpenNotificationsButton = styled(MenuButton)`
  display: flex;
  align-items: center;
  border-style: none;
  background-color: transparent;
  width: 3.125rem; // 50px
  cursor: pointer;
`

export const ButtonText = styled(MenuText)`
  font-weight: 500;
`

export const UserContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.625rem; // 10px
  white-space: normal;
  color: ${({ theme }) => theme["gray-700"]};
`

export const UserFullName = styled.span<MenuProps>`
  font-size: 0.75rem; // 12px
  font-weight: 500;
  text-align: start;

  transition: opacity .3s ease-in-out;
  opacity: ${({ menuIsExpanded }) => menuIsExpanded ? 1 : 0};

  margin-bottom: 0.1875rem; // 3px

`

export const UserEmail = styled.span<MenuProps>`
   font-size: 0.625rem; // 10px
  transition: opacity .3s ease-in-out;
  opacity: ${({ menuIsExpanded }) => menuIsExpanded ? 1 : 0};
`

export const SelectedMenuHighlighter = styled.div`
  position: absolute;
  height: 100%;
  width: 0.3125rem; // 5px
  left: 0;
  border-radius: 0 2px 2px 0;
  background-color: ${({ theme }) => theme["cyan-300"]};
`

export const BranchSwapButton = styled.button<MenuProps>`
  position: absolute;
  right: 0.625rem; // 10px
  padding: 0.125rem; // 2px
  border-radius: 0.25rem; // 4px
  background-color: ${defaultTheme["cyan-700"]};
  border: none;
  cursor: pointer;

  opacity: ${({ menuIsExpanded }) => menuIsExpanded ? 1 : 0};

  transition: opacity .2s ease-in-out;

  &:hover {
    background-color: orange;
    transition: background-color .2s ease-in-out; 
  }

`
