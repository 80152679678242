/* eslint-disable react/display-name */
import { BottomTabBarProps, createBottomTabNavigator } from "@react-navigation/bottom-tabs"
import { NavigationContainer } from "@react-navigation/native"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import React, { useEffect, useState, useMemo } from "react"
import { ActivityIndicator, View } from "react-native"
import { useNotifications } from "../context/NotifcationContext.web"
import Navigation from "../models/navigation"
import "react-native-gesture-handler"
import evaluateFunction from "../services/evaluateFunction"
import setTranslations from "../services/setTranslations"
import { defaultTheme } from "../styles/themes/default"
import Header from "../views/components/Header/index.web"
import CalendarScreen from "../views/screens/CalendarScreen/index.web"
import DashboardsBuilderScreen from "../views/screens/DashboardsBuilderScreen"
import DashBoardScreen from "../views/screens/DashBoardScreen"
import HomeScreen from "../views/screens/HomeScreen"
import LauncherComposerScreen from "../views/screens/LauncherComposerScreen/index.web"
import LauncherPanelScreen from "../views/screens/LauncherPanelScreen/index.web"
import LoadingScreen from "../views/screens/LoadingScreen"
import LoginScreen from "../views/screens/LoginScreen"
import OrderComposerScreen from "../views/screens/OrderComposerScreen"
import PanelScreen from "../views/screens/PanelScreen"
import RegistrationScreen from "../views/screens/Registration"
import ReportScreen from "../views/screens/ReportScreen"
import ScreenBuilderScreen from "../views/screens/ScreenBuilderScreen"

const Stack = createNativeStackNavigator()
const Tab = createBottomTabNavigator()

function Root() {
  const [tabs, setTabs] = useState([])

  useEffect(() => {
    Navigation.setTabsFunction(setTabs)
  }, [])

  const getComponent = useMemo(() => (component: string) => {
    const components = {
      "Calendar": CalendarScreen,
      "Dashboard": DashBoardScreen,
      "DashboardsBuilder": DashboardsBuilderScreen,
      "Hierarchic": RegistrationScreen,
      "OrderComposer": OrderComposerStack,
      "OrderPanel": PanelScreen,
      "Registration": RegistrationScreen,
      "ScreenBuilder": ScreenBuilderScreen,
      "Reports": ReportScreen,
      "LauncherPanel": LauncherPanelScreen,
      "NavigationComposer": LauncherStack
    }
    return components[component]
  }, [])

  const renderTab = useMemo(
    () => ({ tabName, component, params }) => (
      <Tab.Screen
        key={tabName}
        name={tabName}
        component={getComponent(component)}
        initialParams={params}
        options={{ lazy: true }}
      />
    ),
    [getComponent]
  )

  const renderHeader = (headerProps: BottomTabBarProps) => <Header {...headerProps} />

  return (
    <Tab.Navigator screenOptions={{
      headerShown: true,
      title: "",
      headerStyle: { height: 60 }
    }} tabBar={renderHeader}>
      <Tab.Screen name="Overview" component={HomeScreen} />
      {tabs.map(renderTab)}
    </Tab.Navigator>
  )
}

function OrderComposerStack({ route, navigation }) {
  const [controller] = useState(route.params.controller)
  const [isLoading, setIsLoading] = useState(true)

  const [screens, setScreens] = useState(() => {
    if (route.params.initialScreen) {
      return [{
        name: route.params.initialScreen.name,
        params: route.params.initialScreen
      }]
    } else return []
  })

  useEffect(() => {
    controller.setScreens = setScreens
    controller.navigation = navigation

    const fetchData = async () => {
      setIsLoading(true)
      if (route.params.initialScreen) {
        const { initialScreen } = route.params
        await controller.getNavigationFields(route.params.initialScreen.transactionId, true)
        const triggers = controller.triggers
        const record = initialScreen.models()["T_MOV_HDR"]

        for (const field of controller.sideBarFields) {
          const params = {
            ENTITY: field.T_DTD_ATR.ENTITY.ID,
            RECORD: record
          }
          for (const trigger of triggers) {
            await evaluateFunction(trigger.EXPRESSION, initialScreen.models(), params)
          }
        }
      }
      setIsLoading(false)
    }

    fetchData()

    return () => {
      controller.setScreens = null
      controller.navigation = null
    }
  }, [controller, navigation, route.params.initialScreen])

  if (isLoading) {
    return (
      <View style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center"
      }}>
        <ActivityIndicator size={"large"} color={defaultTheme["cyan-700"]} />
      </View>
    )
  } else {
    return (
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        {screens.map(({ name, params }) => (
          <Stack.Screen key={name} name={name} component={OrderComposerScreen} initialParams={params} />
        )
        )}
        <Stack.Screen name="Transaction" component={OrderComposerScreen} initialParams={controller.orderPrototype.TNSID} options={{ presentation: "modal" }} />
      </Stack.Navigator>
    )
  }
}

function LauncherStack({ route, navigation }) {

  const [controller] = useState(route.params.controller)
  const [isLoading, setIsLoading] = useState(true)
  const [screens, setScreens] = useState(() => {
    if (route.params.isInitialScreen && !route.params?.existingDocument) {
      return [{
        name: route.params.initialParams.name,
        params: {
          ...route.params.initialParams.params,
          name: route.params.initialParams.name
        }
      }]
    } else return []
  })

  useEffect(() => {
    controller.setScreens = setScreens
    controller.navigation = navigation

    if (route.params?.existingDocument) {
      (async () => await getNavigation().then(() => setIsLoading(false)))()
    } else setIsLoading(false)

    return () => {
      controller.setScreens = null
      controller.navigation = null
    }
  }, [controller, navigation, route.params.initialScreen])

  const getNavigation = async () => {
    const { initialParams, existingDocument } = route.params
    const { HDRfield } = initialParams.params

    await controller.getNavigationFields(existingDocument[HDRfield.ID].ID, true, existingDocument)

  }

  if (isLoading) {
    return (
      <View style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center"
      }}>
        <ActivityIndicator size={"large"} color={defaultTheme["cyan-700"]} />
      </View>)
  } else return (
    <Stack.Navigator screenOptions={{ headerShown: false }} >
      {screens.map(({ name, params }) => (
        <Stack.Screen key={name} name={name} component={LauncherComposerScreen} initialParams={params} />
      ))}
    </Stack.Navigator>
  )
}

export default function Routes() {

  const [isLoading, setIsLoading] = useState(true)

  const { unReadNotifications } = useNotifications()

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      await setTranslations(false)
      setTimeout(() => setIsLoading(false), 1000)
    })()
  }, [])

  if (isLoading) return <LoadingScreen />

  return (
    <NavigationContainer documentTitle={{ formatter: () => unReadNotifications.length > 0 ? `Musa (${unReadNotifications.length})` : "Musa" }}>
      <Stack.Navigator>
        <Stack.Screen name="Login" component={LoginScreen} options={{ headerShown: false }} />
        <Stack.Screen name="Root" component={Root} options={{ headerShown: false }} />
      </Stack.Navigator>
    </NavigationContainer>
  )
}
