import models from "../models"
import dbRequest from "../services/dbRequest/dbRequest"
import getTableFields from "../services/registration/getTableFields"
import DynamicCard from "../views/components/cards/DynamicCard"
import BaseController from "./BaseController"
import listDecorator from "./decorators/listDecorator"

export default class PanelController extends BaseController {

  constructor(params) {

    super()
    this.modelsTemplate = models.getClone()
    this.models = () => this.modelsTemplate
    listDecorator(this, DynamicCard)

    this.listEntityName = () => params.isLauncherScreen ? params.ID : "T_MOV_HDR"

    this.screenName = () => params.DESCRIPTION
  }

  async getRecords() {
    return await dbRequest
      .loadRecords(this.listEntityName())
      .execute()
  }

  async getFields() {
    const fields = await getTableFields({ entityName: this.listEntityName() })
    return fields.sort((a, b) => a?.T_DTD_ATR?.CARDORDERBY - b?.T_DTD_ATR?.CARDORDERBY)
  }

  async getItemsOrders(ID) {
    const entity = `T_MOV_HDR/${ID}/T_MOV_ITE`
    return await dbRequest.loadRecords(entity).execute()
  }
}
